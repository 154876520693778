import { fetchMarketplaceArticles } from '@km/api/service/marketplace/fetchMarketplaceArticles';
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
var logger = getLogger('useFetchAheadArticle');
export function useFetchAheadArticle(locale) {
  var _useState = useState(null),
      article = _useState[0],
      setArticle = _useState[1];

  useEffect(function () {
    fetchMarketplaceArticles(locale).then(function (results) {
      if (results.length > 0) {
        setArticle(results[0]);
      }
    }).catch(function (err) {
      logger.error(err);
    });
  }, [locale]);
  return article;
}