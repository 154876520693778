import { CLEAR_APP_STATE } from '@km/state/app/actions';
import { ACTION_TYPE_SET_MAINTENANCE } from './setMaintenanceAction';
export var initialState = {
  isMaintenance: false
};
export function maintenanceReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case ACTION_TYPE_SET_MAINTENANCE:
      {
        var setMaintenanceAction = action;
        return Object.assign({}, state, setMaintenanceAction.payload);
      }

    case CLEAR_APP_STATE:
      return initialState;

    default:
      return state;
  }
}