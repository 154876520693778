import { machineKpiHistoryProps } from '@km/api/service/machine/utils/graphql';
import { sendGraphlQlRequest } from '@km/api/sendApiRequest';
export var KPI_ATTRIBUTE;

(function (KPI_ATTRIBUTE) {
  KPI_ATTRIBUTE["DeviceId"] = "DeviceId";
  KPI_ATTRIBUTE["TimestampCreated"] = "TimestampCreated";
  KPI_ATTRIBUTE["Productivity"] = "Productivity";
  KPI_ATTRIBUTE["Stability"] = "Stability";
  KPI_ATTRIBUTE["MachineState"] = "MachineState";
})(KPI_ATTRIBUTE || (KPI_ATTRIBUTE = {}));

export function fetchKpiHistory(_ref, endpointUrl) {
  var DeviceId = _ref.DeviceId,
      Limit = _ref.Limit,
      From = _ref.From,
      NextToken = _ref.NextToken,
      Attribute = _ref.Attribute;
  var params = {
    DeviceId: DeviceId,
    Limit: Limit,
    From: From,
    NextToken: NextToken,
    Attribute: Attribute
  };
  return sendGraphlQlRequest("\n    query GetMachineHistory ($DeviceId: String!, $Attribute: MachineHistoryAttribute, $From: Float, $To: Float, $Limit: Int, $NextToken: String) {\n      GetMachineHistory (DeviceId: $DeviceId, Attribute: $Attribute, From: $From, To: $To, Limit: $Limit, NextToken: $NextToken) {\n        " + machineKpiHistoryProps + "\n      }\n    }", Object.assign({}, params), 'GET', endpointUrl).then(function (res) {
    return res.GetMachineHistory;
  });
}