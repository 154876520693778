import { makeAction } from '@km/state/util/makeAction';
export var ACTION_TYPE_ADD_MACHINE_TARGET_DATA = 'ADD_MACHINE_TARGET_DATA';
export var addMachineTargetData = function addMachineTargetData(deviceId, from, to, data) {
  if (data === void 0) {
    data = [];
  }

  return makeAction(ACTION_TYPE_ADD_MACHINE_TARGET_DATA, {
    from: from,
    to: to,
    deviceId: deviceId,
    data: data
  });
};