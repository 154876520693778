import { SET_CONDITION_MONITORING_PAGE_SORT } from './condition-monitoring-page.actions';
import { MACHINE_SORT_KEYS } from '@km/components/machines/table/machineSortKeys';
var initialState = {
  sort: MACHINE_SORT_KEYS.NAME_ASC
};
export var conditionMonitoringPageReduer = function conditionMonitoringPageReduer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case SET_CONDITION_MONITORING_PAGE_SORT:
      return Object.assign({}, state, {
        sort: action.payload
      });

    default:
      return state;
  }
};