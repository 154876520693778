import "core-js/modules/es.object.from-entries";
import isNil from 'lodash/isNil';
export var monitoringAttributeList = ['SetCntPrt', 'SetCustomerDesc', 'SetDescJob', 'SetDescOp', 'SetDescPrt', 'SetDescMld', 'SetRecMld', 'SetCntMld', 'ActCntMld', 'SetRecMldNxt', 'SetCntPrtBox', 'SetDescMat_*'];
export function getReduceToMonitoringAttributes(attributes) {
  return function (acc, attr) {
    if (attr.endsWith('_*')) {
      var attrName = attr.split('_')[0];
      var attrList = Object.entries(attributes).filter(function (_ref) {
        var key = _ref[0],
            value = _ref[1];
        return key.split('_')[0] === attrName;
      });
      return Object.assign({}, acc, Object.fromEntries(attrList));
    } else {
      var attrTitle = attributes[attr];

      if (!isNil(attrTitle)) {
        var _Object$assign;

        return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[attr] = attrTitle, _Object$assign));
      }
    }

    return acc;
  };
}