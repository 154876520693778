import moment from 'moment';
import { EventTypeDisplayValues } from '@km/utils/events';
import { PostType } from '@km/api/model/PostType';
export function getEventDatasets(data, translate) {
  if (data === void 0) {
    data = [];
  }

  // each type of event gets one data set
  var dataSets = Object.keys(PostType).map(function (eventType) {
    var posts = filterPostsByType(data, eventType);
    var eventColor = EventTypeDisplayValues[eventType];
    return toEventChartDataSet(posts, translate ? translate('message-types.' + eventType) : eventType, eventColor);
  });
  return dataSets;
}

function filterPostsByType(posts, type) {
  if (posts === void 0) {
    posts = [];
  }

  return posts.filter(function (post) {
    return post.PostType === type;
  });
}

export function getEventAxis() {
  return {
    yaxis100: {
      visible: false,
      type: 'linear',
      domain: [0, 0.05]
    }
  };
}

function toEventChartDataSet(data, label, eventcolor) {
  return {
    name: label,
    x: data.map(function (i) {
      return moment(i.Timestamp * 1000).format();
    }),
    y: data.map(function (i) {
      return 0;
    }),
    xaxis: 'x',
    yaxis: 'y100',
    //set to a sufficiently high axis number
    hovertext: data.map(function (i) {
      return label + ": " + i.Message || label;
    }),
    hoverinfo: 'text',
    type: 'scatter',
    mode: 'markers',
    marker: {
      color: eventcolor.color,
      symbol: 'diamond',
      size: 8
    }
  };
}