import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { MACHINE_STATES } from '@km/api/model/MACHINE_STATES';
import { getMachineProductivity, getMachineStability, isMachineProducing } from '@km/model/machine';
export var SortOrders = {
  ASC: 'ascending',
  DSC: 'descending'
};
export function sortByDeviceName(array, order) {
  var result = array.sort(function (a, b) {
    var _a$MachineName, _b$MachineName;

    var diff = sortByName((_a$MachineName = a.MachineName) !== null && _a$MachineName !== void 0 ? _a$MachineName : a.DeviceId, (_b$MachineName = b.MachineName) !== null && _b$MachineName !== void 0 ? _b$MachineName : b.DeviceId);
    return validateOrder(diff, order);
  });
  return result;
}
export function sortByStatus(array, order) {
  var result = array.sort(function (a, b) {
    var diff;

    if (a.MachineState === b.MachineState) {
      diff = sortByName(a.DeviceId, b.DeviceId);
    } else if (new Set([MACHINE_STATES.PRODUCTION_READY, MACHINE_STATES.MACHINE_ONLINE]).has(a.MachineState)) {
      diff = -1;
    } else if (new Set([MACHINE_STATES.PRODUCTION_READY, MACHINE_STATES.MACHINE_ONLINE]).has(b.MachineState)) {
      diff = 1;
    } else {
      diff = 1;
    }

    return validateOrder(diff, order);
  });
  return result;
}
export function sortByRunningStatus(array, order) {
  var isProducing = function isProducing(machine) {
    return isMachineProducing(machine) ? 1 : 0;
  };

  var result = array.sort(function (a, b) {
    var diff;

    if (isProducing(a) === isProducing(b)) {
      diff = sortByName(a.DeviceId, b.DeviceId);
    } else {
      diff = isProducing(a) - isProducing(b);
    }

    return validateOrder(diff, order);
  });
  return result;
}
export function sortByStability(array, order) {
  var result = array.sort(function (a, b) {
    var diff; // apparently, stability can be undefined

    var StabilityA = getMachineStability(a);
    var StabilityB = getMachineStability(b);

    if (StabilityA < StabilityB) {
      diff = -1;
    } else if (StabilityA > StabilityB) {
      diff = 1;
    } else {
      diff = sortByName(a.DeviceId, b.DeviceId);
    }

    return validateOrder(diff, order);
  });
  return result;
}
export function sortByProductivity(array, rangeParam, order) {
  var result = array.sort(function (a, b) {
    var _getMachineProductivi, _getMachineProductivi2;

    var aProd = (_getMachineProductivi = getMachineProductivity(a, rangeParam)) !== null && _getMachineProductivi !== void 0 ? _getMachineProductivi : 0;
    var bProd = (_getMachineProductivi2 = getMachineProductivity(b, rangeParam)) !== null && _getMachineProductivi2 !== void 0 ? _getMachineProductivi2 : 0;
    var diff;

    if (aProd < bProd) {
      diff = -1;
    } else if (aProd > bProd) {
      diff = 1;
    } else {
      diff = sortByName(a.DeviceId, b.DeviceId);
    }

    return validateOrder(diff, order);
  });
  return result;
}

function sortByName(a, b) {
  return a.toLowerCase().localeCompare(b.toLowerCase());
}

function validateOrder(diff, order) {
  if (order === SortOrders.ASC) {
    return diff;
  }

  return -1 * diff;
}

export var sortByDeviceNameASC = function sortByDeviceNameASC(machines) {
  return sortByDeviceName(_toConsumableArray(machines), SortOrders.ASC);
};
export var sortByDeviceNameDSC = function sortByDeviceNameDSC(machines) {
  return sortByDeviceName(_toConsumableArray(machines), SortOrders.DSC);
};
export var sortByStatusASC = function sortByStatusASC(machines) {
  return sortByStatus(_toConsumableArray(machines), SortOrders.ASC);
};
export var sortByStatusDSC = function sortByStatusDSC(machines) {
  return sortByStatus(_toConsumableArray(machines), SortOrders.DSC);
};
export var sortByRunningStatusASC = function sortByRunningStatusASC(machines) {
  return sortByRunningStatus(_toConsumableArray(machines), SortOrders.ASC);
};
export var sortByRunningStatusDSC = function sortByRunningStatusDSC(machines) {
  return sortByRunningStatus(_toConsumableArray(machines), SortOrders.DSC);
};
export var sortByStabilityASC = function sortByStabilityASC(machines) {
  return sortByStability(_toConsumableArray(machines), SortOrders.ASC);
};
export var sortByStabilityDSC = function sortByStabilityDSC(machines) {
  return sortByStability(_toConsumableArray(machines), SortOrders.DSC);
};
export var sortByProductivityASC = function sortByProductivityASC(machines, rangeParam) {
  return sortByProductivity(_toConsumableArray(machines), rangeParam, SortOrders.ASC);
};
export var sortByProductivityDSC = function sortByProductivityDSC(machines, rangeParam) {
  return sortByProductivity(_toConsumableArray(machines), rangeParam, SortOrders.DSC);
};