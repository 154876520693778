import { createPostMutation } from './utils/graphql';
import { sendGraphlQlRequest } from '@km/api/sendApiRequest';
import { PostCreatorType } from '@km/api/model/PostCreatorTypes';
export function createPost(AuthenticatedUserId, TimelineId, message, endpointUrl, uuid, imageName) {
  return sendGraphlQlRequest(createPostMutation, {
    input: {
      TimelineId: TimelineId,
      Message: {
        Text: message
      },
      UUID: uuid,
      ImageName: imageName
    }
  }, 'POST', endpointUrl).then(function (result) {
    var inputMessage = result.CreatePost;
    var post = {
      TimelineId: TimelineId,
      UUID: inputMessage.UUID,
      PostType: inputMessage.PostType,
      Timestamp: inputMessage.Timestamp,
      Message: inputMessage.Message[0].Text,
      ts: inputMessage.Timestamp,
      Creator: {
        Type: PostCreatorType.USER,
        Id: AuthenticatedUserId
      }
    };
    return post;
  });
}