import { PostType } from '@km/api/model/PostType';
import { PostCreatorType } from '@km/api/model/PostCreatorTypes';
import moment from 'moment';
import { PostState } from '@km/api/model/Post';
export var PENDING_ID_PROP = '___pending__'; // tslint:disable-next-line: variable-name

var __pendingIdCount__ = 0;

var idGen = function idGen() {
  return PENDING_ID_PROP + (__pendingIdCount__ = (__pendingIdCount__ + 1) % 10000);
};

export function makePendingPost(TimelineId, message, user) {
  var pending = {
    Locale: '',
    PostType: PostType.USER_POST,
    Creator: {
      Id: (user === null || user === void 0 ? void 0 : user.UserId) || '',
      Type: PostCreatorType.USER
    },
    TimelineId: TimelineId,
    Timestamp: moment().unix(),
    UUID: idGen(),
    ts: moment().valueOf(),
    Message: message,
    PostState: PostState.SEND
  };
  pending[PENDING_ID_PROP] = true;
  return pending;
}
export var isPostPending = function isPostPending(post) {
  return !!post[PENDING_ID_PROP];
};