export var RequestStatus;

(function (RequestStatus) {
  RequestStatus["NotStarted"] = "NotStarted";
  RequestStatus["InProgress"] = "InProgress";
  RequestStatus["Finished"] = "Finished";
  RequestStatus["Error"] = "Error";
})(RequestStatus || (RequestStatus = {}));

export function isRequestFinished(request) {
  return request === RequestStatus.Finished;
}
export function isRequestInProgress(request) {
  return request === RequestStatus.InProgress;
}
export function canStartRequest(request) {
  return request === RequestStatus.Error || request === RequestStatus.NotStarted;
}