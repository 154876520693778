import "core-js/modules/es.array.reduce";

/**
 *  Returns {[timelineId]: Post[]}
 *
 * @param {*} state
 * @param {*} range
 */
export var selectPostsBetween = function selectPostsBetween(posts, from, to, ids) {
  return (ids ? ids : Object.keys(posts)).reduce(function (result, timelineId) {
    var _Object$assign;

    return Object.assign({}, result, (_Object$assign = {}, _Object$assign[timelineId] = posts.length > 0 ? posts.filter(function (post) {
      var Timestamp = post.Timestamp;
      return Timestamp >= from.unix() && Timestamp <= to.unix();
    }) : [], _Object$assign));
  }, {});
};
export var selectPostsOlder = function selectPostsOlder(posts, from, amount) {
  if (amount === void 0) {
    amount = 0;
  }

  if (!posts) return [];
  var idx = posts.findIndex(function (post) {
    return post.Timestamp <= from;
  });
  return amount > 0 ? posts.slice(idx, amount) : posts.slice(idx);
};