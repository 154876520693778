import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { CLOSE_SNACKBAR, ENQUEUE_SNACKBAR, REMOVE_SNACKBAR } from './actions';
import { formExceptionsCodes } from '../util/formExceptionsCodes';
import { CLEAR_APP_STATE } from '@km/state/app/actions';
var initialState = {
  snackbars: [],
  formErrors: []
};
export var snackbarReducer = function snackbarReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  if (action.isError) {
    var _action$payload;

    if (formExceptionsCodes.includes((_action$payload = action.payload) === null || _action$payload === void 0 ? void 0 : _action$payload.code)) {
      return Object.assign({}, state, {
        formErrors: [].concat(_toConsumableArray(state.formErrors), [action.payload])
      });
    }

    return Object.assign({}, state);
  }

  switch (action.type) {
    case CLEAR_APP_STATE:
      return initialState;

    case ENQUEUE_SNACKBAR:
      return Object.assign({}, state, {
        snackbars: [].concat(_toConsumableArray(state.snackbars), [Object.assign({
          key: action.key
        }, action.notification)])
      });

    case CLOSE_SNACKBAR:
      return Object.assign({}, state, {
        snackbars: state.snackbars.map(function (noti) {
          return action.dismissAll || noti.key === action.key ? Object.assign({}, noti, {
            dismissed: true
          }) : Object.assign({}, noti);
        })
      });

    case REMOVE_SNACKBAR:
      return Object.assign({}, state, {
        snackbars: state.snackbars.filter(function (noti) {
          return noti.key !== action.key;
        })
      });

    default:
      return state;
  }
};
export default snackbarReducer;