import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _isEmpty from "lodash/isEmpty";
import { setBreadcrumb } from '@km/state/breadcrumb/actions/setBreadcrumb';
import { createBreadcrumbItem } from '@km/state/breadcrumb/types/BreadcrumbItem';
import { HOME_BREADCRUMB_KEY } from '@km/components/breadcrumb/breadcrumbMapper';
export function getHistoryListener(dispatch) {
  return function (_ref) {
    var action = _ref.action,
        location = _ref.location;
    var pathname = location.pathname;
    var pathItems = pathname.substring(1).split('/');

    switch (action) {
      case 'PUSH':
      case 'POP':
        dispatch(setBreadcrumb(pathItems.reduce(function (acc, pathItem, index, arr) {
          var fullPath = arr.slice(0, index).join('/');
          return [].concat(_toConsumableArray(acc), [createBreadcrumbItem(pathItem, fullPath)]);
        }, [])));
        break;

      default:
        throw new Error("Action type " + action + " not implemented");
    }
  };
}
export function getBreadcrumbDispatchItems(pathItems) {
  return pathItems.map(function (item) {
    return createBreadcrumbItem(_isEmpty(item) ? HOME_BREADCRUMB_KEY : item);
  });
}