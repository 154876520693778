import { useEffect, useRef } from 'react';
export function useInterval(callback, delay, leading) {
  if (leading === void 0) {
    leading = true;
  }

  var savedCallback = useRef();
  useEffect(function () {
    savedCallback.current = callback;
  }, [callback]);

  for (var _len = arguments.length, triggers = new Array(_len > 3 ? _len - 3 : 0), _key = 3; _key < _len; _key++) {
    triggers[_key - 3] = arguments[_key];
  }

  useEffect(function () {
    function tick() {
      savedCallback && savedCallback.current && savedCallback.current();
    }

    if (leading) {
      tick();
    }

    if (delay !== null) {
      var id = setInterval(tick, delay);
      return function () {
        return clearInterval(id);
      };
    }
  }, [delay].concat(triggers));
}