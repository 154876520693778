import { fetchMarketplaceMachines } from '@km/api/service/marketplace/fetchMarketplaceMachines';
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
var logger = getLogger('useFetchMarketplaceMachine');
export function useFetchMarketplaceMachine(locale) {
  var _useState = useState(null),
      machine = _useState[0],
      setMachine = _useState[1];

  useEffect(function () {
    fetchMarketplaceMachines(locale).then(function (results) {
      if (results.length > 0) {
        setMachine(results[0]);
      }
    }).catch(function (err) {
      logger.error(err);
    });
  }, [locale]);
  return machine;
}