import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { CLEAR_APP_STATE } from '../app/actions';
import { ADD_MACHINE_NOTIFICATION, CLEAR_MACHINE_NOTIFICATIONS } from './actions';
var initialState = {};

var notificationsReducer = function notificationsReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case CLEAR_APP_STATE:
      return initialState;

    case ADD_MACHINE_NOTIFICATION:
      return addMachineNotification(state, action);

    case CLEAR_MACHINE_NOTIFICATIONS:
      return clearMachineNotifications(state, action);

    default:
      break;
  }

  return state;
};

export default notificationsReducer;

function addMachineNotification(state, action) {
  var _Object$assign;

  var currentNotifcations = state[action.payload.DeviceId] || [];
  return Object.assign({}, state, (_Object$assign = {}, _Object$assign[action.payload.DeviceId] = [].concat(_toConsumableArray(currentNotifcations), [action.payload.notification]), _Object$assign));
}

function clearMachineNotifications(state, action) {
  var _Object$assign2;

  return Object.assign({}, state, (_Object$assign2 = {}, _Object$assign2[action.payload.DeviceId] = null, _Object$assign2));
}