import { navigate } from '@reach/router';
/**
 * Watch for state changes in the login form and if the user is
 * authenticated, route him to the landingpage or the route
 * he requested before he had to log in.
 *
 * @param { string } authState
 * @param { Object } location
 */

export var onAuthStateChange = function onAuthStateChange(location, isAuthenticated) {
  if (isAuthenticated) {
    var requestedLocation = getPathWithSearch(location);
    var currentLocation = getPathWithSearch(location); // setUser(user);
    // TODO - find out is it still valid - this was probably needed only for safari
    // workaround to enable depplinking in S3 buckets
    // redirect is triggered in 404.js
    // if (exists(window) && location.hash.startsWith('#!/')) {
    //   navigate(`${pathname}${location.hash.substr(2)}`);
    //   return;
    // }

    if (needsRelocation(requestedLocation, currentLocation)) {
      navigate(requestedLocation);
    }

    return null;
  }
};

function getPathWithSearch(location) {
  var pathname = location.pathname,
      search = location.search;
  return "" + pathname + search;
}

function needsRelocation(requestedLocation, currentLocation) {
  return requestedLocation !== currentLocation && requestedLocation !== '/';
}