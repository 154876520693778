import "core-js/modules/es.array.reduce";
import { getLogger } from '@km/utils/logger';
import { MACHINE_ATTRIBUTES } from '@km/api/model/MACHINE_ATTRIBUTES';
import { getChunkTimestamps, isBetweenOrAtEdge } from '@km/utils/timeseries';
var logger = getLogger('selectAttributeValues');
export var selectAttributeValues = function selectAttributeValues(deviceId, attributes, from, to, durationInSeconds, machineRawDataModel, limit) {
  if (durationInSeconds === void 0) {
    durationInSeconds = 30 * 60;
  }

  logger.debug('deviceID:', deviceId, 'Attributes', attributes, 'From:', from, 'To:', to);

  if (from && to && from > to) {
    logger.warn('from is greater than to!');
  }

  if (!machineRawDataModel) {
    return {};
  }

  return attributes.filter(function (attribute) {
    return attribute !== MACHINE_ATTRIBUTES.Timestamp;
  }).map(function (attribute) {
    return {
      attribute: attribute,
      attributeStore: machineRawDataModel[attribute]
    };
  }).filter(function (_ref) {
    var attributeStore = _ref.attributeStore;
    return attributeStore && attributeStore.chunks;
  }).map(function (_ref2) {
    var attribute = _ref2.attribute,
        attributeStore = _ref2.attributeStore;
    var chunks = attributeStore.chunks,
        latestTo = attributeStore.latestTo;

    var _getChunkTimestamps = getChunkTimestamps(chunks),
        values = _getChunkTimestamps.values,
        timestamps = _getChunkTimestamps.timestamps;

    if (from && to) {
      var _ref4;

      logger.debug('from and to defined...');
      var vals = timestamps.filter(function (ts) {
        return isBetweenOrAtEdge(ts, from, to);
      }).map(function (ts) {
        var _ref3;

        return _ref3 = {}, _ref3[attribute] = values[ts], _ref3.TimestampEdge = ts, _ref3;
      }).slice(0, limit);
      return _ref4 = {}, _ref4[attribute] = vals, _ref4;
    } else {
      var _ref6;

      logger.debug('from and to NOT defined...', latestTo);

      var _vals = timestamps.filter(function (ts) {
        return isBetweenOrAtEdge(ts, latestTo - durationInSeconds, latestTo);
      }).map(function (ts) {
        var _ref5;

        return _ref5 = {}, _ref5[attribute] = values[ts], _ref5.TimestampEdge = ts, _ref5;
      }).slice(0, limit);

      return _ref6 = {}, _ref6[attribute] = _vals, _ref6;
    }
  }).reduce(function (map, values) {
    return Object.assign({}, map, values);
  }, {});
};