import { queryAll } from '@km/utils/request';
import { getLogger } from '@km/utils/logger';
import { postAggregator } from './utils/postAggregator';
import { GetPostsQuery } from './utils/graphql';
import { sendGraphlQlRequest } from '@km/api/sendApiRequest';
export var fpLogger = getLogger('fetchPosts');
export function fetchPosts(params, endpointUrl, fetchAll) {
  if (fetchAll === void 0) {
    fetchAll = false;
  }

  return queryAll(function (nextToken) {
    if (nextToken === void 0) {
      nextToken = '';
    }

    return sendGraphlQlRequest(GetPostsQuery, Object.assign({}, params, {
      nextToken: nextToken
    }), 'GET', endpointUrl);
  }, params, postAggregator, 'ListPosts.NextToken', 'ListPosts.Items', 'ListPosts.NextToken', fetchAll);
}