import "core-js/modules/es.array.reduce";
import "core-js/modules/es.promise.finally";
import _isNil from "lodash/isNil";
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
import { useMachineRawDataAttributes } from '@km/hooks/machines/useMachineRawDataAttributes';
import { useMachineRawData } from '@km/hooks/machines/useMachineRawData';
import { isRequestInProgress } from '@km/utils/RequestStatus';
import { fetchGlobalTenantDevice } from '@feat/conditionmonitoring/api/fetchGlobalTenantDevice';
import { useTranslation } from 'react-i18next';
import { getLocaleFromCode } from '@km/utils/getLocale';
var logger = getLogger('useFetchMachineList');
export function useDevice(deviceId) {
  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var _useState = useState(null),
      device = _useState[0],
      setDevice = _useState[1];

  var _useState2 = useState(true),
      isLoading = _useState2[0],
      setIsLoading = _useState2[1];

  var _useMachineRawDataAtt = useMachineRawDataAttributes('condition_monitoring', [deviceId], getLocaleFromCode(i18n.language), !_isNil(device)),
      fetchAttributesStatus = _useMachineRawDataAtt[0],
      attributesMap = _useMachineRawDataAtt[1];

  var _useMachineRawData = useMachineRawData('condition_monitoring', [deviceId], mapAttributes(attributesMap), !isRequestInProgress(fetchAttributesStatus), undefined, undefined, 1),
      rawDataFetchStatus = _useMachineRawData[0],
      conditionMonitoringRawData = _useMachineRawData[1];

  useEffect(function () {
    setIsLoading(true);

    if (_isNil(device)) {
      fetchGlobalTenantDevice({
        DeviceId: deviceId
      }).then(function (result) {
        var device = result;
        setDevice(device);
      }).catch(function (err) {
        logger.error(err);
      }).finally(function () {
        return setIsLoading(false);
      });
    }
  }, [deviceId]);
  return {
    device: device,
    isFetchingDevice: isLoading || isRequestInProgress(fetchAttributesStatus) || isRequestInProgress(rawDataFetchStatus),
    attributesMap: attributesMap,
    conditionMonitoringRawData: conditionMonitoringRawData
  };
}

function mapAttributes(attr) {
  return Object.entries(attr).filter(function (_ref) {
    var id = _ref[0],
        value = _ref[1];
    return !_isNil(value);
  }).reduce(function (acc, _ref2) {
    var _Object$assign;

    var id = _ref2[0],
        value = _ref2[1];
    return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[id] = Object.keys(value.attributes), _Object$assign));
  }, {});
}