import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _cloneDeep from "lodash/cloneDeep";
import _minBy from "lodash/minBy";
import _maxBy from "lodash/maxBy";
import { consolidateChunks } from '@km/utils/timeseries';
import { isDefined, attributeKeys } from '@km/utils';
import { ADD_MACHINE_DATA } from './actions/addMachineData';
import { CLEAR_APP_STATE } from '../app/actions';
import { ACTION_TYPE_ADD_MACHINE_TARGET_DATA } from '@km/state/machinedata/actions/addMachineTargetData';
export var initialState = {
  byMachineId: {},
  targetDataByMachineId: {}
};

var log = function log() {};

var machinesReducer = function machinesReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case CLEAR_APP_STATE:
      return initialState;

    case ADD_MACHINE_DATA:
      {
        var addMachineDataAction = action;
        var machineDataState = addMachineData(action.payload, state.byMachineId[addMachineDataAction.payload.deviceId]);

        if (machineDataState !== null) {
          var _Object$assign;

          return Object.assign({}, state, {
            byMachineId: Object.assign({}, state.byMachineId, (_Object$assign = {}, _Object$assign[action.payload.deviceId] = machineDataState, _Object$assign))
          });
        }

        return state;
      }

    case ACTION_TYPE_ADD_MACHINE_TARGET_DATA:
      {
        var addMachineTargetDataAction = action;
        var deviceId = addMachineTargetDataAction.payload.deviceId;
        var machineTargetDataState = addMachineData(action.payload, state.targetDataByMachineId[addMachineTargetDataAction.payload.deviceId]);

        if (machineTargetDataState !== null) {
          var _Object$assign2;

          return Object.assign({}, state, {
            targetDataByMachineId: Object.assign({}, state.targetDataByMachineId, (_Object$assign2 = {}, _Object$assign2[deviceId] = machineTargetDataState, _Object$assign2))
          });
        }

        return state;
      }

    default:
      break;
  }

  return state;
};

export default machinesReducer;
/**
 * @TODO DRY up: refactor and use valueChunkActionHandler
 *
 */

function addMachineData(payload, machineData) {
  var from = payload.from,
      to = payload.to;
  var _payload$data = payload.data,
      data = _payload$data === void 0 ? [] : _payload$data,
      deviceId = payload.deviceId;

  if (!isDefined(deviceId)) {
    console.warn('No device id found in action payload. Cannot add data.');
    return null;
  }
  /**
   * if from or to is undefined => interpolate from received data
   */


  if (!from && !to && data && data.length > 0) {
    log('from and to are not defined, getting fallback values from data...');
    from = (_minBy(data, 'Timestamp') || {
      Timestamp: undefined
    }).Timestamp;
    to = (_maxBy(data, 'Timestamp') || {
      Timestamp: undefined
    }).Timestamp;
  }

  var s = machineData;
  /**
   * 1. add the data
   * 2. consolidate the data => merge ranges that connect/overlap
   */

  var deviceState = _cloneDeep(Object.assign({}, machineData || {}));
  /**
   * transform received data to chunks
   */


  var transformed = data.reduce(function (values, value) {
    var TimestampEdge = value.TimestampEdge,
        attributeValues = _objectWithoutPropertiesLoose(value, ["TimestampEdge"]);

    var valueKeys = attributeKeys(attributeValues);

    if (!valueKeys.length) {
      return values;
    }

    return [].concat(_toConsumableArray(values), _toConsumableArray(Object.keys(attributeValues).map(function (key) {
      return {
        ts: TimestampEdge,
        name: key,
        value: attributeValues[key]
      };
    })));
  }, []).reduce(function (store, value) {
    var _Object$assign3;

    var chunk = store[value.name] || {
      name: value.name,
      values: {},
      from: from,
      to: to
    };

    if (chunk) {
      chunk.values[value.ts] = value.value;
    }

    return Object.assign({}, store, (_Object$assign3 = {}, _Object$assign3[value.name] = chunk, _Object$assign3));
  }, {});

  if (attributeKeys(transformed).length) {
    log('Attribute data:', data);
    log('Transformed data:', transformed);
  }
  /**
   * add the chunks to the device store
   */


  Object.keys(transformed).forEach(function (attributeName) {
    var chunk = transformed[attributeName];
    var attributeStore = deviceState[attributeName] || {
      chunks: []
    };
    log('--------------------------');
    log('consolidate chunks for', attributeName);
    log('available chunks for', attributeName, attributeStore);
    log('new chunk for', attributeName, chunk);
    var newChunks = consolidateChunks([].concat(_toConsumableArray(attributeStore.chunks), [chunk]));
    log('consolidated chunks:', newChunks);

    var _newChunks$reduce = newChunks.reduce(function (_ref, chunk) {
      var latestTo = _ref.latestTo;
      return {
        latestTo: Math.max(latestTo, chunk.to)
      };
    }, {
      latestTo: 0
    }),
        latestTo = _newChunks$reduce.latestTo;

    deviceState[attributeName] = {
      latestTo: latestTo,
      chunks: newChunks
    };
  });
  return deviceState;
}