import { machineRawDataProps } from '@km/api/service/machine/utils/graphql';
import { sendGraphlQlRequest } from '@km/api/sendApiRequest';
import { queryAll } from '@km/utils/request';
export function fetchMachineRawData(params, endpointUrl, parameters) {
  if (parameters === void 0) {
    parameters = machineRawDataProps;
  }

  return sendGraphlQlRequest("\n    query GetRawData ($Topic: Topic!, $DeviceId: String!, $Attributes: [String], $From: Float, $To: Float, $Limit: Int, $NextToken: String) {\n      GetRawData (Topic: $Topic, DeviceId: $DeviceId, Attributes: $Attributes, From: $From, To: $To, Limit: $Limit, NextToken: $NextToken) {\n        " + parameters + "\n      }\n    }", params, 'GET', endpointUrl).then(function (res) {
    return res.GetRawData;
  });
}
export function fetchAllMachineRawData(params, endpointUrl, fetchAll) {
  if (fetchAll === void 0) {
    fetchAll = false;
  }

  return queryAll(function (NextToken) {
    if (NextToken === void 0) {
      NextToken = '';
    }

    return fetchMachineRawData(Object.assign({}, params, {
      NextToken: NextToken
    }), endpointUrl);
  }, params, rawDataAggregator, 'NextToken', 'RawData', 'NextToken', fetchAll);
}
export function rawDataAggregator(result, current) {
  if (!result) {
    return current;
  }

  result = result.concat(current);
  return result;
}