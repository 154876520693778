import { makeAction } from '@km/state/util/makeAction';
export var SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES';
export var UNSET_UNREAD_MESSAGES = 'UNSET_UNREAD_MESSAGES';
export var setUnreadMessages = function setUnreadMessages(timelineId) {
  var _makeAction;

  return makeAction(SET_UNREAD_MESSAGES, (_makeAction = {}, _makeAction[timelineId] = {
    hasNewMessages: true
  }, _makeAction));
};
export var unsetUnreadMessages = function unsetUnreadMessages(timelineId) {
  var _makeAction2;

  return makeAction(UNSET_UNREAD_MESSAGES, (_makeAction2 = {}, _makeAction2[timelineId] = {
    hasNewMessages: false
  }, _makeAction2));
};