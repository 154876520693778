import { SET_PROCESS_PAGE_SORT, SET_PROCESS_PAGE_RANGE } from './process-page.actions';
import { MACHINE_SORT_KEYS } from '@km/components/machines/table/machineSortKeys';
import { DURATION_KEYS } from '@km/components/machines/durations';
var initialState = {
  sort: MACHINE_SORT_KEYS.NAME_ASC,
  range: DURATION_KEYS.last24Hours
};

var processPageReducer = function processPageReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case SET_PROCESS_PAGE_SORT:
      return Object.assign({}, state, {
        sort: action.payload
      });

    case SET_PROCESS_PAGE_RANGE:
      return Object.assign({}, state, {
        range: action.payload
      });

    default:
      return state;
  }
};

export default processPageReducer;