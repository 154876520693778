import { OverviewListType } from '@km/components/filter/controlbar/OverviewListType';
import { useEffect, useState } from 'react';
import { LocalStorageService } from '@km/utils/LocalStorageService';
var OverviewListTypeKey = 'OverviewListType';
export function useOverviewListType(defaultListType) {
  var _ref;

  var _useState = useState((_ref = defaultListType !== null && defaultListType !== void 0 ? defaultListType : LocalStorageService.getService().getItem(OverviewListTypeKey)) !== null && _ref !== void 0 ? _ref : OverviewListType.Grid),
      selectedListType = _useState[0],
      setSelectedListType = _useState[1];

  useEffect(function () {
    LocalStorageService.getService().setItem(OverviewListTypeKey, selectedListType);
  }, [selectedListType]);
  return [selectedListType, setSelectedListType];
}