export var MACHINE_ATTRIBUTES;

(function (MACHINE_ATTRIBUTES) {
  MACHINE_ATTRIBUTES["DeviceId"] = "DeviceId";
  MACHINE_ATTRIBUTES["Timestamp"] = "Timestamp";
  MACHINE_ATTRIBUTES["ActMachProd"] = "ActMachProd";
  MACHINE_ATTRIBUTES["ActiveAlarm"] = "ActiveAlarm";
  MACHINE_ATTRIBUTES["MachineStatusRunning"] = "MachineStatusRunning";
  MACHINE_ATTRIBUTES["MachineMode"] = "MachineMode";
  MACHINE_ATTRIBUTES["DeviceStatus"] = "DeviceStatus";
  MACHINE_ATTRIBUTES["ConnectionStatus"] = "ConnectionStatus";
})(MACHINE_ATTRIBUTES || (MACHINE_ATTRIBUTES = {}));