import "core-js/modules/es.array.reduce";
import _set from "lodash/set";
import _get from "lodash/get";
// TODO use this for all requests
export function queryAll(requestFn, params, aggregate, pathToToken, pathToData, nextTokenPropName, fetchAll) {
  if (pathToData === void 0) {
    pathToData = '';
  }

  if (nextTokenPropName === void 0) {
    nextTokenPropName = 'nextToken';
  }

  if (fetchAll === void 0) {
    fetchAll = false;
  }

  var results = [];
  var resolve, reject;
  var promise = new Promise(function (_resolve, _reject) {
    resolve = _resolve;
    reject = _reject;
  });

  var execute = function execute(nextToken) {
    if (nextToken === void 0) {
      nextToken = '';
    }

    return requestFn(nextToken).then(checkResult);
  };

  var checkResult = function checkResult(result) {
    var nextToken = _get(result, pathToToken);

    if (nextToken && fetchAll) {
      _set(result, pathToToken, undefined);

      if (pathToData) {
        results.push(_get(result, pathToData));
      } else {
        results.push(result);
      }

      return execute(nextToken);
    } else {
      if (pathToData) {
        results.push(_get(result, pathToData));
      } else {
        results.push(result);
      }

      if (results && aggregate) {
        results = results.reduce(aggregate, undefined);
      }

      resolve(results);
      return results;
    }
  };

  execute().catch(function (error) {
    console.error(error.response);
    reject(error);
  });
  return promise.then(function (res) {
    return res;
  });
}