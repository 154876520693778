import { combineReducers } from 'redux';
import machineDataReducer from './machinedata/reducers';
import processPageReducer from '../../features/processsupport/pages/overviewpage/state/process-page.reducer';
import notificationsReducer from './notifications/reducers';
import messagesReducer from './messages/reducers';
import { appReducer } from '@km/state/app/appReducer';
import { breadcrumbReducer } from '@km/state/breadcrumb/reducer';
import { machinesFilterReducer } from '@km/state/machineFilter/reducers';
import { timelineReducer } from '@km/state/timeline/reducer';
import { menuReducer } from '@km/state/menu/reducer';
import monitorPageReducer from '../../features/productionmonitoring/pages/detailpage/state/monitor-page.reducers';
import { unreadMessagesReducer } from './unreadMessages/reducers/unreadMessagesReducer';
import { maintenanceReducer } from './maintenance/reducer';
import { conditionMonitoringPageReduer } from '@feat/conditionmonitoring/components/dashboard/grid/state/condition-monitoring-page.reducers';
export var rootReducer = combineReducers({
  breadcrumb: breadcrumbReducer,
  machinedata: machineDataReducer,
  machinesFilter: machinesFilterReducer,
  monitorPage: monitorPageReducer,
  conditionMonitoringPage: conditionMonitoringPageReduer,
  notifications: notificationsReducer,
  processPage: processPageReducer,
  requestInProgress: appReducer,
  snackbars: messagesReducer,
  timelineExpansion: timelineReducer,
  menuExpansion: menuReducer,
  maintenance: maintenanceReducer,
  unreadMessages: unreadMessagesReducer
});