import "core-js/modules/es.array.reduce";
import _isNil from "lodash/isNil";
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
import { fetchGlobalTenantDevices } from '@km/api/service/machine/fetchGlobalTenantDevices';
import { useCurrentTenant } from '../useCurrentTenant';
import { isMachineActive, isMachineProducing } from '@km/model/machine';
import { MachinesSummary } from '@km/components/marketplace/summary/MachinesSummary';
import { fetchAllTenantMachines } from '@km/api/service/machine/fetchAllTenantMachines';
var logger = getLogger('useFetchMachineList');
export function useFetchMachineList(parameters, globalParameters) {
  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  var _useState = useState(null),
      devices = _useState[0],
      setDevices = _useState[1];

  var _useState2 = useState(null),
      machines = _useState2[0],
      setMachines = _useState2[1];

  var _useState3 = useState([]),
      machineIds = _useState3[0],
      setMachineIds = _useState3[1];

  var _useState4 = useState(0),
      machinesCount = _useState4[0],
      setMachinesCount = _useState4[1];

  var _useState5 = useState(true),
      isLoading = _useState5[0],
      setIsLoading = _useState5[1];

  var _useState6 = useState(new MachinesSummary()),
      machinesSummary = _useState6[0],
      setMachinesSummary = _useState6[1];

  var _useState7 = useState([]),
      mergedMachines = _useState7[0],
      setMergeMachines = _useState7[1];

  var refetchMachines = function refetchMachines() {
    setDevices(null);
    setMachines(null);
  };

  var getMachinesSummary = function getMachinesSummary(machines) {
    setMachinesSummary(machines.reduce(function (acc, machine) {
      var machineActive = isMachineActive(machine.MachineState);
      var machineProducing = isMachineProducing(machine);
      machineActive ? ++acc.total.online : ++acc.total.offline;
      machineProducing ? ++acc.producing.total : machineActive && ++acc.producing.stopped;
      return acc;
    }, new MachinesSummary()));
  };

  var getMachineIds = function getMachineIds(machines) {
    setMachineIds(machines.map(function (machine) {
      return machine.DeviceId;
    }));
  };

  useEffect(function () {
    if (!_isNil(currentTenant)) {
      setIsLoading(true);
      var tenantId = currentTenant.TenantId;
      var graphQlEndpoint = currentTenant.GraphqlEndpoint;

      if (_isNil(devices)) {
        fetchGlobalTenantDevices({
          Limit: 1000,
          TenantId: tenantId,
          parameters: globalParameters
        }).then(function (result) {
          setDevices(result.Items);
        }).catch(function (err) {
          logger.error(err);
        });
      }

      if (devices && _isNil(machines)) {
        setIsLoading(true);
        var deviceIds = devices === null || devices === void 0 ? void 0 : devices.map(function (machine) {
          return machine.DeviceId;
        });
        fetchAllTenantMachines({
          DeviceIds: deviceIds
        }, graphQlEndpoint, parameters).then(function (result) {
          setMachines(result.Items);
        }).catch(function (err) {
          logger.error(err);
        });
      }
    }

    if (devices && machines) {
      setIsLoading(true);

      var _mergedMachines = devices.map(function (machine) {
        var device = machines.filter(function (item) {
          return item.DeviceId === machine.DeviceId;
        });

        if (device && device.length > 0) {
          return Object.assign({}, device[0], machine);
        }

        return machine;
      });

      getMachinesSummary(_mergedMachines);
      getMachineIds(_mergedMachines);
      setMergeMachines(_mergedMachines);
      setMachinesCount(_mergedMachines.length);
      setIsLoading(false);
    }
  }, [devices, machines, currentTenant]);
  return {
    machines: mergedMachines,
    machinesCount: machinesCount,
    machineIds: machineIds,
    machinesSummary: machinesSummary,
    isFetchingMachines: isLoading,
    refetchMachines: refetchMachines
  };
}