import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import zip from 'lodash/zip';
export function aggregateMachineHistoricalStates(kpiData, limit) {
  if (kpiData.length <= limit) {
    return kpiData;
  }

  if (limit < 2) {
    throw new Error('Limit must be set to at leat 2');
  } else if (limit === 2) {
    return [kpiData[0], kpiData[kpiData.length - 1]];
  }

  var headKpiList = kpiData.slice(0, -1);
  var tailKpiList = kpiData.slice(1);
  var mergedArrays = zip(headKpiList, tailKpiList);
  var kpiDurationsList = mergedArrays.map(function (_ref) {
    var head = _ref[0],
        tail = _ref[1];
    return Object.assign({}, head, {
      duration: (tail === null || tail === void 0 ? void 0 : tail.Timestamp) - (head === null || head === void 0 ? void 0 : head.Timestamp)
    });
  });
  var durationList = kpiDurationsList.map(function (itemWithDuration) {
    return itemWithDuration.duration;
  }).sort(function (duration1, duration2) {
    if (duration1 < duration2) {
      return -1;
    } else if (duration1 > duration2) {
      return 1;
    }

    return 0;
  }).reverse();
  var threshold = durationList[limit - 2];
  var centerOfArray = kpiDurationsList.slice(1, -1);
  var aggregatedInner = centerOfArray.filter(function (itemWithDuration) {
    return itemWithDuration.duration > threshold;
  }).map(function (item) {
    return {
      Timestamp: item.Timestamp,
      MachineState: item.MachineState
    };
  });
  return [kpiData[0]].concat(_toConsumableArray(aggregatedInner), _toConsumableArray(kpiData.slice(kpiData.length - 1)));
}