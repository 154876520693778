export var PostType;

(function (PostType) {
  PostType["MACHINE_ALARM"] = "MACHINE_ALARM";
  PostType["MACHINE_EVENT"] = "MACHINE_EVENT";
  PostType["PROCESS_SUPPORT"] = "PROCESS_SUPPORT";
  PostType["USER_POST"] = "USER_POST";
  PostType["MACHINE_POST"] = "MACHINE_POST";
  PostType["CONDITION_MONITORING"] = "CONDITION_MONITORING";
  PostType["PRODUCTION_MONITORING"] = "PRODUCTION_MONITORING";
})(PostType || (PostType = {}));