import { createStyles, makeStyles } from '@material-ui/core/styles';
export var useLayoutStyles = function useLayoutStyles(isFullScreen, drawerWidth) {
  return makeStyles(function (theme) {
    return createStyles({
      root: {
        display: 'flex',
        width: '100%'
      },
      appBar: {
        backgroundColor: 'transparent',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        })
      },
      divider: {
        '&:after': {
          color: 'white',
          content: '" | "'
        },
        '&:last-child:after': {
          content: '""'
        }
      },
      content: {
        minWidth: '50%',
        flexGrow: 1,
        padding: theme.spacing(3),
        paddingLeft: 0,
        backgroundColor: 'transparent',
        paddingRight: isFullScreen ? 0 : theme.typography.pxToRem(30),
        display: 'flex',
        flexDirection: 'column',
        height: "calc(100vh - " + theme.spacing(isFullScreen ? 12.25 : 25) + ")",
        marginTop: isFullScreen ? theme.spacing(35) : theme.spacing(25),
        zIndex: 2,
        overflowWrap: 'anywhere',
        willChange: 'padding',
        transition: 'padding 450ms ease-out 150ms'
      },
      topToolbar: {
        backgroundColor: 'transparent !important'
      },
      languageImg: {
        borderRadius: '50%',
        width: '20px',
        height: '20px'
      },
      titleBox: {
        position: 'fixed',
        width: '100%',
        left: 0,
        top: 0,
        paddingRight: theme.typography.pxToRem(30),
        paddingLeft: isFullScreen ? 30 : drawerWidth + 30,
        backgroundImage: "linear-gradient(" + theme.palette.primary.dark + ", #002a4d)",
        paddingBottom: theme.typography.pxToRem(8),
        zIndex: 3,
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.enteringScreen
        }) + ", padding 450ms cubic-bezier(0.4, 0, 0.6, 1) 0ms"
      },
      animationBox: {
        position: 'fixed',
        width: '100%',
        left: 0,
        top: 105,
        paddingLeft: isFullScreen ? 30 : drawerWidth + 30,
        backgroundImage: 'linear-gradient(#002a4d, #002340)',
        height: 'var(--bg-blue-strip-height)',
        willChange: 'height',
        transition: 'height 450ms 0ms, marginTop 450ms 0ms, padding 450ms cubic-bezier(0.4, 0, 0.6, 1) 0ms, z-index 0ms 0s',
        zIndex: 0
      },
      mainTitle: {
        fontFamily: 'DinProMedium',
        fontSize: theme.typography.pxToRem(24),
        color: 'white'
      },
      mainContent: {
        backgroundColor: theme.palette.background.default,
        padding: theme.spacing(3),
        border: "1px solid " + theme.palette.neutral.light,
        borderRadius: theme.shape.borderRadius,
        width: '100%',
        transition: theme.transitions.create(['width'], {
          duration: theme.transitions.duration.enteringScreen
        })
      },
      avatarContainer: {
        position: 'absolute',
        right: '40px',
        top: '20px',
        display: 'flex',
        justifyContent: 'space-between',
        '& > :last-child': {
          marginLeft: '20px'
        }
      },
      fullScreenAvatar: {
        border: '1px solid white',
        color: 'white',
        backgroundColor: 'transparent',
        cursor: 'pointer'
      },
      fullScreenIcon: {
        fontSize: theme.typography.pxToRem(20)
      },
      hoverSecondaryColor: {
        '&:hover': {
          color: theme.palette.secondary.main
        }
      }
    });
  });
};