export var Units;

(function (Units) {
  Units["Temp"] = "temp";
  Units["Pressure"] = "bar";
  Units["Length"] = "mm";
  Units["Time"] = "s";
  Units["Percentage"] = "percent";
  Units["Capacity"] = "capacity";
  Units["Force"] = "force";
  Units["Energy"] = "energy";
  Units["Speed"] = "speed";
  Units["RotationalSpeed"] = "rpm";
  Units["None"] = "none";
})(Units || (Units = {}));

export var moldAttributeList = ['ActFceClpMax', 'ActTimCfr', 'ActTimCfb', 'ActTimClpFceDown', 'ActTimClpFceUp', 'ActTimLckIn', 'ActTimLckOut', 'ActTimClpCls', 'ActTimClpOpn', 'ActTimCool', 'ActTimDeMld_1', 'ActTimDeMld_2', 'ActTmpMldZn_*', 'EgyMldHtgAct'];
export var plastificationHeatingAttributes = ['ActTmpBrlHop_1', 'ActTmpBrlZn_*'];
export var injectionAttributes = ['ActTimHld_1', 'ActPrsHldHydAve', 'ActPrsHldHydMax_1', 'ActPrsHldHydMax_2', 'ActPrsInjHldSpecAve_1', 'ActPrsInjHldSpecAve_2', 'ActPrsInjHldSpecMax_1', 'ActPrsInjHldSpecMax_2', 'ActPrsMachHydMax', 'ActPrsMachSpecMax', 'ActPrsXfrCav_*', 'ActPrsXfrHyd_1', 'ActPrsXfrSpec_1', 'ActPrsXfrSpec_2', 'ActStrHldMin_1', 'ActStrHldMin_2', 'ActStrXfr_1', 'ActStrXfr_2', 'ActTimInj_1', 'ActTimInj_2', 'ActTimInjuBwd_1', 'ActTimInjuBwd_2', 'ActTimInjuFwd_1', 'ActTimInjuFwd_2', 'ActTimXfr_1', 'ActTimXfr_2', 'ActViscApc_1', 'ActViscApc_2', 'ActVolApc_1', 'ActVolApc_2', 'ActRefChgApc_1', 'ActRefChgApc_2', 'ActMPVApc_1', 'ActMPVApc_2', 'ActHldAdaptApc_1', 'ActHldAdaptApc_2', 'ActPrsCavMax', 'ActPrsCavMax_*'];
export var plastificationAttributes = ['ActPrsPlstSpecAve_1', 'ActPrsPlstSpecAve_2', 'ActPrsPlstSpecMax_1', 'ActPrsPlstSpecMax_2', 'ActSpdPlstAve_1', 'ActSpdPlstAve_2', 'ActSpdPlstMax_1', 'ActSpdPlstMax_2', 'ActStrDcmpPre_1', 'ActStrDcmpPre_2', 'ActStrDcmpPst_1', 'ActStrDcmpPst_2', 'ActStrPlst_1', 'ActStrPlst_2', 'ActTimPlst_1', 'ActTimPlst_2', 'ActVelPlstAve_1', 'ActVelPlstAve_2', 'ActVelPlstMax_1', 'ActVelPlstMax_2'];
export var miscellaneousAttributes = ['ActTimCyc', 'ActTimPause', 'ActTmpCab', 'ActTmpOil_1', 'EgyMainDrvAct'];
export var machineParameterAttributes = {
  Mold: moldAttributeList,
  Plasticizing: plastificationAttributes,
  HeatingZones: plastificationHeatingAttributes,
  Misc: miscellaneousAttributes,
  InjectionAndPacking: injectionAttributes
};
export var processAttributes = [].concat(miscellaneousAttributes, moldAttributeList, plastificationHeatingAttributes, plastificationAttributes, injectionAttributes);
export var targetAttributesMap = new Map([['ActTmpBrlZn_*', 'SetTmpBrlZn_*'], ['ActTmpMldZn_*', 'SetTmpMldZn_*'], ['ActTmpOil_1', 'SetTmpOil'], ['ActTimCyc', 'SetTimCyc'], ['ActStrPlst_1', 'SetStrPlst_1'], ['ActVolPlst_1', 'SetVolPlst_1'], ['ActStrDcmpPre_1', 'SetStrDcmpPre_1'], ['ActVolDcmpPre_1', 'SetVolDcmpPre_1'], ['ActStrDcmpPst_1', 'SetStrDcmpPst_1'], ['ActVolDcmpPst_1', 'SetVolDcmpPst_1'], ['ActStrXfr_1', 'SetStrXfr_1'], ['ActVolXfr_1', 'SetVolXfr_1'], ['ActPrsXfrHyd_1', 'SetPrsXfrHyd_1'], ['ActPrsXfrCav_1', 'SetPrsXfrCav_1'], ['ActPrsXfrSpec_1', 'SetPrsXfrSpec_1'], ['ActTimXfr_1', 'SetTimXfr_1'], ['ActFceClpMax', 'SetFrcClp'], ['ActTmpBrlHop_1', 'SetTmpBrlHop_1'], ['ActCntCyc', 'SetCntCyc'], ['ActCntMld', 'ActCntMld']]);
export var monitoringJobInformationGridAttributes = ['SetCntPrt', 'SetCustomerDesc', 'SetDescJob', 'SetDescOp', 'SetDescPrt', 'SetDescMld', 'SetRecMld', 'SetCntMld', 'ActCntMld', 'SetRecMldNxt', 'SetCntPrtBox', 'SetDescMat_*'];