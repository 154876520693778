import { createStyles, makeStyles } from '@material-ui/core';
export var getHeaderStyles = makeStyles(function (theme) {
  return createStyles({
    machineName: {
      cursor: 'pointer',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    machineNameNotClickable: {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    }
  });
});