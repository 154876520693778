import { v4 as uuid } from 'uuid';
import { getWindow } from '@km/utils/globalWindow';
import { AddTimelineNotification, GetUserNotifications, RemoveTimelineNotification } from './utils/graphql';
import { sendGraphlQlRequest } from '@km/api/sendApiRequest';
var NOTIFICATION_UUID_KEY = 'km_notifications_id';
export function fetchNotificationSettings(TimelineId, TenantId) {
  return sendGraphlQlRequest(GetUserNotifications, {
    TimelineId: TimelineId,
    TenantId: TenantId
  }).then(function (result) {
    return result.GetUserNotifications.Items;
  });
}
export function addTimelineNotifications(input, endpointUrl) {
  return sendGraphlQlRequest(AddTimelineNotification, {
    input: input
  }, 'POST').then(function (result) {
    return result.CreateUserNotification;
  });
}
export function removeTimelineNotifications(input, endpointUrl) {
  return sendGraphlQlRequest(RemoveTimelineNotification, {
    input: input
  }, 'POST').then(function (result) {
    return result.RemoveUserNotification;
  });
}
export function getNotificationUUID() {
  var globalWindow = getWindow();
  var notificationId = globalWindow.localStorage.getItem(NOTIFICATION_UUID_KEY);

  if (!notificationId) {
    var newID = uuid();
    globalWindow.localStorage.setItem(NOTIFICATION_UUID_KEY, newID);
    return newID;
  } else {
    return notificationId;
  }
}