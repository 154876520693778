var _rangesToProductivity;

import { MACHINE_STATES } from '@km/api/model/MACHINE_STATES';
import isNil from 'lodash/isNil';
import floor from 'lodash/floor';
import { DURATION_KEYS } from '@km/components/machines/durations';
export function getMachineStability(machine) {
  var _machine$MachineStabi, _machine$MachineStabi2;

  return isMachineActive(machine.MachineState) ? (_machine$MachineStabi = (_machine$MachineStabi2 = machine.MachineStability) === null || _machine$MachineStabi2 === void 0 ? void 0 : _machine$MachineStabi2.Value) !== null && _machine$MachineStabi !== void 0 ? _machine$MachineStabi : 0 : 0;
}
export var rangesToProductivityMap = (_rangesToProductivity = {}, _rangesToProductivity[DURATION_KEYS.lastHour] = 'Productivity_1h', _rangesToProductivity[DURATION_KEYS.last8Hours] = 'Productivity_8h', _rangesToProductivity[DURATION_KEYS.last24Hours] = 'Productivity_24h', _rangesToProductivity);
export function getMachineProductivity(machine, rangeParam) {
  var _machine$rangesToProd;

  if (isNil(machine[rangesToProductivityMap[rangeParam]])) {
    return null;
  }

  return (_machine$rangesToProd = machine[rangesToProductivityMap[rangeParam]]) !== null && _machine$rangesToProd !== void 0 ? _machine$rangesToProd : 0;
}
export function isMachineProducing(machine, isMachineActiveParam) {
  return !isNil(isMachineActiveParam) ? isMachineActiveParam : isMachineActive(machine.MachineState);
}
export function KpiPercent(value) {
  if (typeof value === 'number') {
    var displayValue = value * 1000 / 10; // this is because 0.009*100=0.8999999999999999 which later floor is 0.8

    if (displayValue === 0 || displayValue < 0.1) {
      return '0';
    }

    if (displayValue < 1) {
      return floor(displayValue, 1).toString();
    }

    if (displayValue > 100) {
      return '100';
    }

    return Math.floor(displayValue).toString();
  }

  return '-';
}
export function getProductionProgress(isActive, ProductionProgress) {
  if (!isActive || !ProductionProgress) {
    return 0;
  }

  return ProductionProgress >= 1 ? 1 : ProductionProgress;
}
export function canShowKpiValue(machine, canShowOnOffline) {
  if (!isNil(machine)) {
    var MachineState = machine.MachineState;
    return isMachineActive(machine === null || machine === void 0 ? void 0 : machine.MachineState) || MachineState === MACHINE_STATES.OFFLINE && canShowOnOffline;
  }

  return false;
}
export function isMachineActive(machineState) {
  if (!isNil(machineState)) {
    return machineState === MACHINE_STATES.PRODUCTION_READY || machineState === MACHINE_STATES.MACHINE_ONLINE;
  }

  return false;
}
export function canShowJobProgressValue(machine) {
  return !isNil(machine) && !isNil(machine === null || machine === void 0 ? void 0 : machine.ProductionProgress);
}
export function isMachineStateProducing(machineState) {
  return machineState === MACHINE_STATES.PRODUCTION_READY;
}