import { useEffect } from 'react';
import { FirebaseAnalyticsManager } from '@km/utils/firebase/FirebaseAnalyticsManager';
export var PageTitles;

(function (PageTitles) {
  PageTitles["LoginPage"] = "Login Page";
  PageTitles["Home"] = "Home Page";
  PageTitles["MonitoringDashboard"] = "Monitoring Dashboard";
  PageTitles["MonitoringDetails"] = "Monitoring Details";
  PageTitles["ProcessDashboard"] = "Process Dashboard";
  PageTitles["ConditionMonitoringDashboard"] = "Condition Monitoring Dashboard";
  PageTitles["ConditionMonitoringDetails"] = "Condition Monitoring Details";
  PageTitles["ProcessDetails"] = "Process Details";
  PageTitles["ProcessGraph"] = "Process Graph";
  PageTitles["DataAnalysis"] = "Data Analysis";
  PageTitles["CookieDeclaration"] = "Cookie Declaration";
  PageTitles["MaintenanceMode"] = "Maintenance";
})(PageTitles || (PageTitles = {}));

export function useFirebaseAnalyticsPageView(pageTitle) {
  useEffect(function () {
    var fam = new FirebaseAnalyticsManager();
    fam.logEvent('page_view', {
      page_title: pageTitle
    });
  }, [pageTitle]);
}