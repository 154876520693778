import { useEffect, useState } from 'react';
export function useAreNotificationEnabled() {
  var _useState = useState(Notification.permission === 'granted'),
      areNotificationEnabled = _useState[0],
      setAreNotificationsEnabled = _useState[1];

  useEffect(function () {
    if ('permissions' in navigator) {
      navigator.permissions.query({
        name: 'notifications'
      }).then(function (notificationPerm) {
        notificationPerm.onchange = function () {
          setAreNotificationsEnabled(notificationPerm.state === 'granted');
        };
      });
    }
  }, []);
  return areNotificationEnabled;
}