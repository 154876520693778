import { getMachineProductivity } from '@km/model/machine';
export var processKpis = [{
  name: 'MachineStability',
  caption: 'processpage.machinelist.header.stability'
}, {
  name: 'Productivity',
  caption: 'processpage.machinelist.header.productivity'
}];
export var monitorKpis = [{
  name: 'ProductionProgress',
  caption: 'machine-props.ProductionProgress'
}, {
  name: 'Productivity',
  caption: 'processpage.machinelist.header.productivity'
}];
export function getKpiValue(paramName, machineData, rangeParam) {
  if (paramName === 'MachineStability') {
    var _machineData$paramNam;

    return (_machineData$paramNam = machineData[paramName]) === null || _machineData$paramNam === void 0 ? void 0 : _machineData$paramNam.Value;
  }

  if (paramName === 'Productivity') {
    return getMachineProductivity(machineData, rangeParam);
  }

  return machineData[paramName];
}