import { mapData } from '@km/utils/parameterChart/getParameterData';
export var LINE_COLOR = '#00325A';
export function getChartData(data, lineColor, label) {
  if (data === void 0) {
    data = [];
  }

  if (lineColor === void 0) {
    lineColor = LINE_COLOR;
  }

  return {
    datasets: [{
      fill: false,
      lineTension: 0.1,
      backgroundColor: lineColor,
      borderColor: lineColor,
      borderCapStyle: 'butt',
      borderDash: [],
      borderDashOffset: 0.0,
      borderJoinStyle: 'miter',
      borderWidth: 1,
      pointBorderColor: lineColor,
      pointBackgroundColor: '#fff',
      pointBorderWidth: 1,
      pointHoverRadius: 5,
      pointHoverBackgroundColor: lineColor,
      pointHoverBorderColor: lineColor,
      pointHoverBorderWidth: 2,
      pointRadius: 0,
      label: label,
      type: 'line',
      pointHitRadius: 2,
      spanGaps: false,
      data: mapData(data)
    }]
  };
}