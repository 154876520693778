import "core-js/modules/es.array.reduce";
export function getMachineToolData(attributeNames, rawData) {
  var machineRawData = rawData;

  if (machineRawData) {
    var requiredAttributes = new Set(attributeNames);
    return Object.entries(machineRawData).sort().reduce(function (acc, _ref) {
      var key = _ref[0],
          value = _ref[1];

      if (requiredAttributes.has(key)) {
        var _Object$assign;

        return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[key] = Object.values(value.chunks[value.chunks.length - 1].values), _Object$assign));
      }

      return acc;
    }, {});
  }

  return null;
}