import _isNil from "lodash/isNil";
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
import { useCurrentTenant } from './useCurrentTenant';
import { fetchUsers } from '@km/api/service/user';
var logger = getLogger('useUserList');
export function useUserList() {
  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  var _useState = useState(null),
      userList = _useState[0],
      setUserList = _useState[1];

  useEffect(function () {
    if (!_isNil(currentTenant)) {
      fetchUsers({
        TenantId: currentTenant.TenantId
      }).then(function (result) {
        setUserList(result);
      }).catch(function (error) {
        logger.error(error);
      });
    }
  }, [currentTenant]);

  var getUserCount = function getUserCount() {
    var _userList$length;

    return (_userList$length = userList === null || userList === void 0 ? void 0 : userList.length) !== null && _userList$length !== void 0 ? _userList$length : 0;
  };

  var getUserById = function getUserById(id) {
    var _userList$find;

    return (_userList$find = userList === null || userList === void 0 ? void 0 : userList.find(function (_ref) {
      var UserId = _ref.UserId;
      return UserId === id;
    })) !== null && _userList$find !== void 0 ? _userList$find : null;
  };

  return {
    usersCount: getUserCount(),
    getUserById: getUserById
  };
}