export var appReducer = function appReducer(state, action) {
  if (state === void 0) {
    state = false;
  }

  switch (action.type) {
    default:
      break;
  }

  return state;
};