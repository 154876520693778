import { useContext } from 'react';
import { PostContext } from '@km/hooks/posts/PostContext';
export function useTimelineFilters() {
  var _postsContext$filters;

  var postsContext = useContext(PostContext);
  return {
    filters: (_postsContext$filters = postsContext === null || postsContext === void 0 ? void 0 : postsContext.filters) !== null && _postsContext$filters !== void 0 ? _postsContext$filters : [],
    setFilters: postsContext === null || postsContext === void 0 ? void 0 : postsContext.setFilters
  };
}