import isNil from 'lodash/isNil';
import { sortByDeviceNameASC, sortByDeviceNameDSC } from '@km/components/machines/table/machinesort';
import { ConditionMonitoringSortingOrder } from './ConditionMonitoringSortingOrder';
export var sortByPlastification = function sortByPlastification(order) {
  return function (machine, machinesData) {
    return machine.sort(function (machineA, machineB) {
      var _AMachineData$value, _BMachineData$value;

      var AMachineData = machinesData[machineA.DeviceId];
      var BMachineData = machinesData[machineB.DeviceId];

      if (isNil(AMachineData) || isNil(BMachineData)) {
        return 0;
      }

      var AValue = (_AMachineData$value = AMachineData.value) !== null && _AMachineData$value !== void 0 ? _AMachineData$value : 0;
      var BValue = (_BMachineData$value = BMachineData.value) !== null && _BMachineData$value !== void 0 ? _BMachineData$value : 0;

      if (AValue < BValue) {
        return order === 'ascending' ? -1 : 1;
      }

      if (AValue > BValue) {
        return order === 'ascending' ? 1 : -1;
      }

      return 0;
    });
  };
};
export var cmSortOptions = [{
  key: ConditionMonitoringSortingOrder.NAME_ASC,
  fn: sortByDeviceNameASC
}, {
  key: ConditionMonitoringSortingOrder.NAME_DSC,
  fn: sortByDeviceNameDSC
}, {
  key: ConditionMonitoringSortingOrder.PLASTIFICATION_ASC,
  fn: sortByPlastification('ascending')
}, {
  key: ConditionMonitoringSortingOrder.PLASTIFICATION_DSC,
  fn: sortByPlastification('descending')
}];