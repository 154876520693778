import { ACTION_TYPE_SET_MACHINES_SORTING_ORDER } from '@km/state/machineFilter/actions/setSortingOrderAction';
import { MachinesSortingOrder } from '@km/utils/MachinesSortingOrder';
import { ACTION_TYPE_SET_MACHINES_FILTER_ACTION } from '@km/state/machineFilter/actions/setMachinesFilterAction';
import { CLEAR_APP_STATE } from '@km/state/app/actions';
var initialState = {
  filterQuery: '',
  sortingOrderId: MachinesSortingOrder.NAME_ASC
};
export var machinesFilterReducer = function machinesFilterReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case CLEAR_APP_STATE:
      return initialState;

    case ACTION_TYPE_SET_MACHINES_SORTING_ORDER:
      {
        var sortingOrderAction = action;
        return Object.assign({}, state, {
          sortingOrderId: sortingOrderAction.payload
        });
      }

    case ACTION_TYPE_SET_MACHINES_FILTER_ACTION:
      {
        var machinesFilterAction = action;
        return Object.assign({}, state, {
          filterQuery: machinesFilterAction.payload
        });
      }

    default:
      return state;
  }
};