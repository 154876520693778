/**
 * Use this function to correct the string values given
 * from moment.js into the correct german plural
 *
 * eine Minute => einer Minute
 * eine Stunde => einer Stunde
 * ein Tag => einem Tag
 * ein Monat => einem Monat
 * ein Jarh => einem Jahr
 *
 * @param value input string from moment.js function .fromNow(true)
 */
export function pluralCorrection(value) {
  var _corrections$find;

  var corrections = [{
    input: 'ein Jahr',
    expected: 'einem Jahr'
  }, {
    input: 'ein Monat',
    expected: 'einem Monat'
  }, {
    input: 'ein Tag',
    expected: 'einem Tag'
  }, {
    input: 'eine Stunde',
    expected: 'einer Stunde'
  }, {
    input: 'eine Minute',
    expected: 'einer Minute'
  }];
  return ((_corrections$find = corrections.find(function (correction) {
    return correction.input === value;
  })) === null || _corrections$find === void 0 ? void 0 : _corrections$find.expected) || value;
}