import { useStaticQuery } from 'gatsby';
/**
 * Return the siteMetadata configurated in gatsby-config.js
 */

export var useSiteMetaData = function useSiteMetaData() {
  var _useStaticQuery = useStaticQuery("3159585216"),
      site = _useStaticQuery.site;

  return site;
};