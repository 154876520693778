import _classPrivateFieldLooseBase from "@babel/runtime/helpers/esm/classPrivateFieldLooseBase";
import _classPrivateFieldLooseKey from "@babel/runtime/helpers/esm/classPrivateFieldLooseKey";
import firebase from 'gatsby-plugin-firebase';
import { getLogger } from "@km/utils/logger";
var logger = getLogger('Firebase Analytics');

var _analytics = _classPrivateFieldLooseKey("analytics");

export var FirebaseAnalyticsManager = function FirebaseAnalyticsManager() {
  var _this = this;

  Object.defineProperty(this, _analytics, {
    writable: true,
    value: void 0
  });

  this.logEvent = function (event, eventParams, options) {
    logger.log("Sending event [type: " + event + ", data: " + JSON.stringify(eventParams !== null && eventParams !== void 0 ? eventParams : {}) + "]");

    _classPrivateFieldLooseBase(_this, _analytics)[_analytics].logEvent(event, eventParams, options);
  };

  _classPrivateFieldLooseBase(this, _analytics)[_analytics] = firebase.analytics();
};