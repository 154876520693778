import { createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import { rootReducer } from './rootReducer';
import { STORE_VERSION_KEY } from './StoreVersion';
import { persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
var storePropWhitelist = ['machinedata', 'posts', 'breadcrumb', 'processPage', 'monitorPage', 'conditionMonitoringPage', 'userSettings', 'unreadMessages', 'timelineExpansion', 'menuExpansion', 'maintenance', STORE_VERSION_KEY // <= important, without this migrations won´t work!!
];
var persistConfig = {
  key: 'root',
  storage: storage,
  whitelist: storePropWhitelist
};
var persistedReducer = persistReducer(persistConfig, rootReducer);
export var store = createStore(persistedReducer, composeWithDevTools());
export var persistor = persistStore(store);