import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { getMachineToolData } from '@km/utils/getMachineToolData';
import { getChartData, LINE_COLOR } from '@km/utils/parameterChart/getChartData';
import { getChartSettings } from '@km/utils/parameterChart/RawDataValueRange';
export function useMachineRawChartData(label, deviceId, attributeNames, dataType, rawData) {
  var machineToolData = getMachineToolData(attributeNames, rawData);
  return getMachineChartRawDataParams(label, attributeNames, machineToolData, dataType);
}
export function getMachineChartRawDataParams(label, attributeNames, machineToolData, dataType) {
  return attributeNames.reduce(function (acc, attribute) {
    if (!attribute) {
      return acc;
    }

    if (attribute.endsWith('_*') && machineToolData) {
      var attributeId = attribute.split('_')[0];
      var keys = Object.keys(machineToolData).filter(function (key) {
        return key.startsWith(attributeId);
      });
      return [].concat(_toConsumableArray(acc), _toConsumableArray(keys));
    }

    return [].concat(_toConsumableArray(acc), [attribute]);
  }, []).reduce(function (acc, attribute) {
    var _Object$assign;

    var chartData = getChartData(machineToolData === null || machineToolData === void 0 ? void 0 : machineToolData[attribute], dataType === 'order' ? '#FF830080' : LINE_COLOR, label);
    return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[attribute] = {
      chartData: chartData,
      valueRange: getChartSettings(chartData.datasets[0].data)
    }, _Object$assign));
  }, {});
}