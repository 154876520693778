import { SET_UNREAD_MESSAGES, UNSET_UNREAD_MESSAGES } from '../actions/unreadMessagesAction';
import { CLEAR_APP_STATE } from '@km/state/app/actions';
var initialState = {};
export function unreadMessagesReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case SET_UNREAD_MESSAGES:
      {
        var newTimestampRecord = action;
        return Object.assign({}, state, newTimestampRecord.payload);
      }

    case UNSET_UNREAD_MESSAGES:
      {
        var _newTimestampRecord = action;
        return Object.assign({}, state, _newTimestampRecord.payload);
      }

    case CLEAR_APP_STATE:
      return initialState;

    default:
      return state;
  }
}