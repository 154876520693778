import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
export var DEFAULT_LOCALE = 'en_US';
export var langs = {
  en: {
    name: 'English',
    codes: ['en', 'en_US', 'en_GB', 'en-GB', 'en-US'],
    locale: 'en_US'
  },
  de: {
    name: 'Deutsch',
    codes: ['de', 'de_DE', 'de-DE'],
    locale: 'de_DE'
  },
  es: {
    name: 'Español',
    codes: ['es', 'es_ES', 'es-ES'],
    locale: 'es_ES'
  },
  cs: {
    name: 'Czech',
    codes: ['cs', 'cs_CZ', 'cs-CZ'],
    locale: 'cs_CZ'
  },
  fr: {
    name: 'French',
    codes: ['fr', 'fr_FR', 'fr-FR'],
    locale: 'fr_FR'
  },
  cn: {
    name: '中国',
    codes: ['cn', 'zh_CN', 'zh-CN'],
    locale: 'zh_CN'
  }
};
export var langCodes = Object.keys(langs).reduce(function (prev, curr) {
  //prev is an array after first iteration
  return typeof prev === 'string' ? [].concat(_toConsumableArray(langs[prev].codes), _toConsumableArray(langs[curr].codes)) : [].concat(_toConsumableArray(prev), _toConsumableArray(langs[curr].codes));
});
export var localeMap = {
  en: 'en_US',
  de: 'de_DE',
  es: 'es_ES',
  cs: 'cs_CZ',
  fr: 'fr_FR',
  cn: 'zh_CN'
};
export function isSupportedLang(code) {
  return langCodes.includes(code);
}
export function getLangKey(code) {
  return Object.keys(langs).find(function (key) {
    return langs[key].codes.includes(code);
  });
}
export function getLocale(supportedLocale, separator) {
  var _localeMap$supportedL;

  var locale = (_localeMap$supportedL = localeMap[supportedLocale]) !== null && _localeMap$supportedL !== void 0 ? _localeMap$supportedL : localeMap.en;

  if (locale && separator) {
    return locale.replace('_', separator);
  }

  return locale;
}
export function getLocaleFromCode(code) {
  var langKey = getLangKey(code);
  return langKey ? langs[langKey].locale : DEFAULT_LOCALE;
}