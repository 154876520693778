import { SortingOrder } from '@km/components/dashboard/list/reducers/SortingOrder';
import { getKpiValue } from '@km/utils/machine/MachineDetailKPIs';
import { isMachineActive } from '@km/model/machine';
import { MACHINE_STATES } from '@km/api/model/MACHINE_STATES';
import { sortByDeviceNameASC, sortByDeviceNameDSC, sortByStatusASC, sortByStatusDSC } from '@km/components/machines/table/machinesort';
export function sortMachinesList(machines, sortingState, rangeParam) {
  var order = sortingState.order,
      key = sortingState.key;

  switch (key) {
    case 'name':
      return order === SortingOrder.Asc ? sortByDeviceNameASC(machines) : sortByDeviceNameDSC(machines);

    case 'status':
      return order === SortingOrder.Asc ? sortByStatusASC(machines) : sortByStatusDSC(machines);

    default:
      return machines.sort(function (firstMachine, secondMachine) {
        return sortMachineByName(firstMachine, secondMachine, order);
      }).sort(function (firstMachine, secondMachine) {
        var _getKpiValue, _getKpiValue2;

        var isFirstActiveState = isMachineActive(firstMachine.MachineState);
        var isSecondActiveState = isMachineActive(secondMachine.MachineState);
        var firstVal = isFirstActiveState ? (_getKpiValue = getKpiValue(key, firstMachine, rangeParam)) !== null && _getKpiValue !== void 0 ? _getKpiValue : 0 : 0;
        var secondVal = isSecondActiveState ? (_getKpiValue2 = getKpiValue(key, secondMachine, rangeParam)) !== null && _getKpiValue2 !== void 0 ? _getKpiValue2 : 0 : 0;

        if (firstVal > secondVal) {
          return order === SortingOrder.Asc ? 1 : -1;
        } else if (secondVal > firstVal) {
          return order === SortingOrder.Asc ? -1 : 1;
        } else {
          return 0;
        }
      });
  }
}

function sortMachineByName(firstMachine, secondMachine, order) {
  var _first$MachineName, _second$MachineName;

  var _getOrderedMachines = getOrderedMachines(firstMachine, secondMachine, order),
      first = _getOrderedMachines[0],
      second = _getOrderedMachines[1];

  return ((_first$MachineName = first.MachineName) !== null && _first$MachineName !== void 0 ? _first$MachineName : first.DeviceId).localeCompare((_second$MachineName = second.MachineName) !== null && _second$MachineName !== void 0 ? _second$MachineName : second.DeviceId, undefined, {
    numeric: true,
    sensitivity: 'base'
  });
}

function sortMachineByStatus(firstMachine, secondMachine, order) {
  var _getOrderedMachines2 = getOrderedMachines(firstMachine, secondMachine, order),
      first = _getOrderedMachines2[0],
      second = _getOrderedMachines2[1];

  if (first.MachineState !== MACHINE_STATES.OFFLINE && second.MachineState !== MACHINE_STATES.OFFLINE || first.MachineState === MACHINE_STATES.OFFLINE && second.MachineState === MACHINE_STATES.OFFLINE) {
    return sortMachineByName(first, second, order);
  }

  if (first.MachineState !== MACHINE_STATES.OFFLINE) {
    return 1;
  }

  if (secondMachine.MachineState !== MACHINE_STATES.OFFLINE) {
    return 1;
  }

  return -1;
}

function getOrderedMachines(firstMachine, secondMachine, order) {
  return order === SortingOrder.Asc ? [firstMachine, secondMachine] : [secondMachine, firstMachine];
}