export var defaultColorList = {
  1: '#DE5050',
  2: '#de6c50',
  3: '#fd7435',
  4: '#fd9235',
  5: '#fdad35',
  6: '#FDD835',
  7: '#bfe083',
  8: '#588c2b',
  9: '#2b8c5a',
  10: '#2B8C78'
};
export var singleColor = {
  1: '#7D7D7D'
};