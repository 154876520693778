export var ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export var CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';
export var REMOVE_SNACKBAR = 'REMOVE_SNACKBAR';
export var enqueueSnackbar = function enqueueSnackbar(notification) {
  var key = notification.options && notification.options.key;
  return {
    type: ENQUEUE_SNACKBAR,
    payload: '',
    notification: Object.assign({}, notification, {
      key: key || new Date().getTime() + Math.random()
    })
  };
};
export var closeSnackbar = function closeSnackbar(key) {
  return {
    type: CLOSE_SNACKBAR,
    dismissAll: !key,
    key: key
  };
};
export var removeSnackbar = function removeSnackbar(key) {
  return {
    type: REMOVE_SNACKBAR,
    key: key
  };
};