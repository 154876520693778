import { makeAction } from '@km/state/util/makeAction';
export var ADD_MACHINE_NOTIFICATION = 'ADD_MACHINE_NOTIFICATION';
export var addMachineNotification = function addMachineNotification(DeviceId, notification) {
  return makeAction(ADD_MACHINE_NOTIFICATION, {
    notification: notification,
    DeviceId: DeviceId
  });
};
/**
 * Clear machine notifications
 */

export var CLEAR_MACHINE_NOTIFICATIONS = 'CLEAR_MACHINE_NOTIFICATIONS';
export var clearMachineNotifications = function clearMachineNotifications(DeviceId) {
  return makeAction(CLEAR_MACHINE_NOTIFICATIONS, {
    DeviceId: DeviceId
  });
};