import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import _minBy from "lodash/minBy";
import _maxBy from "lodash/maxBy";
import _cloneDeep from "lodash/cloneDeep";
import { attributeKeys, isDefined } from '@km/utils';
import { consolidateChunks } from '@km/utils/timeseries';
export function getRawDataChunks(_ref) {
  var deviceId = _ref.deviceId,
      _ref$data = _ref.data,
      data = _ref$data === void 0 ? [] : _ref$data,
      machineData = _ref.machineData,
      from = _ref.from,
      to = _ref.to;

  if (!isDefined(deviceId)) {
    console.warn('No device id found in action payload. Cannot add data.');
    return null;
  }
  /**
   * if from or to is undefined => interpolate from received data
   */


  if (!from && !to && data && data.length > 0) {
    from = (_minBy(data, 'Timestamp') || {
      Timestamp: undefined
    }).Timestamp;
    to = (_maxBy(data, 'Timestamp') || {
      Timestamp: undefined
    }).Timestamp;
  }
  /**
   * 1. add the data
   * 2. consolidate the data => merge ranges that connect/overlap
   */


  var deviceState = _cloneDeep(Object.assign({}, machineData || {}));
  /**
   * transform received data to chunks
   */


  var transformed = data.reduce(function (values, value) {
    var TimestampEdge = value.TimestampEdge,
        attributeValues = _objectWithoutPropertiesLoose(value, ["TimestampEdge"]);

    var valueKeys = attributeKeys(attributeValues);

    if (!valueKeys.length) {
      return values;
    }

    return [].concat(_toConsumableArray(values), _toConsumableArray(Object.keys(attributeValues).map(function (key) {
      return {
        ts: TimestampEdge,
        name: key,
        value: attributeValues[key]
      };
    })));
  }, []).reduce(function (store, value) {
    var _Object$assign;

    var chunk = store[value.name] || {
      name: value.name,
      values: {},
      from: from,
      to: to
    };

    if (chunk) {
      chunk.values[value.ts] = value.value;
    }

    return Object.assign({}, store, (_Object$assign = {}, _Object$assign[value.name] = chunk, _Object$assign));
  }, {});
  /**
   * add the chunks to the device store
   */

  Object.keys(transformed).forEach(function (attributeName) {
    var chunk = transformed[attributeName];
    var attributeStore = deviceState[attributeName] || {
      chunks: []
    };
    var newChunks = consolidateChunks([].concat(_toConsumableArray(attributeStore.chunks), [chunk]));

    var _newChunks$reduce = newChunks.reduce(function (_ref2, chunk) {
      var latestTo = _ref2.latestTo;
      return {
        latestTo: Math.max(latestTo, chunk.to)
      };
    }, {
      latestTo: 0
    }),
        latestTo = _newChunks$reduce.latestTo;

    deviceState[attributeName] = {
      latestTo: latestTo,
      chunks: newChunks
    };
  });
  return deviceState;
}