import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/es';
import 'moment/locale/cs';
import 'moment/locale/fr';
import 'moment/locale/zh-cn';
import en from './en.json';
import de from './de.json';
import es from './es.json';
import cs from './cs.json';
import fr from './fr.json';
import cn from './cn.json'; // the translations
// (tip move them in a JSON file and import them)

var resources = {
  en: {
    translation: en
  },
  de: {
    translation: de
  },
  es: {
    translation: es
  },
  cs: {
    translation: cs
  },
  fr: {
    translation: fr
  },
  cn: {
    translation: cn
  }
};
i18n.on('initialized', function (options) {
  moment.locale(options.lng);
});
i18n.on('languageChanged', function (lng) {
  moment.locale(lng);
});
i18n.use(LanguageDetector).use(initReactI18next) // passes i18n down to react-i18next
.init({
  detection: {
    order: ['localStorage', 'navigator'],
    // cache user language on
    caches: ['localStorage']
  },
  fallbackLng: ['en', 'de'],
  resources: resources,
  keySeparator: '.',
  // we do not use keys in form messages.welcome
  interpolation: {
    escapeValue: false,
    // react already safes from xss
    prefix: '{',
    suffix: '}'
  }
});
export default i18n;