import { useState } from 'react';
import { usePosts } from '@km/hooks/posts/usePosts';
import isEmpty from 'lodash/isEmpty';
export function usePostsConfig() {
  var _useState = useState({}),
      postsConfig = _useState[0],
      setPostsConfig = _useState[1];

  var _useState2 = useState([]),
      postFilters = _useState2[0],
      setPostFilters = _useState2[1];

  var _useState3 = useState([]),
      notifications = _useState3[0],
      setNotifications = _useState3[1];

  var _useState4 = useState(false),
      areNotificationsEnabled = _useState4[0],
      setAreNotificationsEnabled = _useState4[1];

  var _usePosts = usePosts(Object.assign({}, postsConfig, {
    applyFilters: true
  }), postFilters),
      _usePosts$posts = _usePosts.posts,
      posts = _usePosts$posts === void 0 ? [] : _usePosts$posts,
      createPost = _usePosts.createPost,
      addPost = _usePosts.addPost,
      latestTs = _usePosts.latestTs,
      requestStatus = _usePosts.requestStatus;

  var updatePostConfig = function updatePostConfig(config) {
    if (isEmpty(config)) {
      setPostsConfig({});
      setPostFilters([]);
    } else if (config.DeviceId && config.DeviceId !== postsConfig.DeviceId) {
      setPostsConfig(config);
      setPostFilters([]);
    } else {
      setPostsConfig(Object.assign({}, postsConfig, config));
    }
  };

  return {
    postsData: {
      requestStatus: requestStatus,
      latestTs: latestTs,
      createPost: createPost,
      posts: posts,
      addPost: addPost
    },
    filters: postFilters,
    notifications: notifications,
    areNotificationsEnabled: areNotificationsEnabled,
    setFilters: setPostFilters,
    setNotifications: setNotifications,
    setAreNotificationsEnabled: setAreNotificationsEnabled,
    updatePostConfig: updatePostConfig
  };
}