import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { ACTION_TYPE_SET_BREADCRUMB } from '@km/state/breadcrumb/actions/setBreadcrumb';
import { getWindow } from '@km/utils/globalWindow';
import { createBreadcrumbItem } from '@km/state/breadcrumb/types/BreadcrumbItem';
import { ACTION_TYPE_SET_BREADCRUMB_TITLE_MAP } from '@km/state/breadcrumb/actions/setBreadcrumbTitleMap';
import { CLEAR_APP_STATE } from '@km/state/app/actions';
var defaultState = [createBreadcrumbItem(getWindow().location.pathname.split('/')[1])];
export function breadcrumbReducer(state, action) {
  if (state === void 0) {
    state = defaultState;
  }

  switch (action.type) {
    case CLEAR_APP_STATE:
      return defaultState;

    case ACTION_TYPE_SET_BREADCRUMB:
      {
        var setBreadcrumbAction = action;
        var breadcrumbItems = setBreadcrumbAction.payload;

        if (breadcrumbItems.length > state.length) {
          return [].concat(_toConsumableArray(state), [breadcrumbItems[breadcrumbItems.length - 1]]);
        }

        return setBreadcrumbAction.payload.reduce(function (acc, item, index) {
          return [].concat(_toConsumableArray(acc), [Object.assign({}, item, {
            name: state[index].name
          })]);
        }, []);
      }

    case ACTION_TYPE_SET_BREADCRUMB_TITLE_MAP:
      {
        var setTitleMapAction = action;
        var _setTitleMapAction$pa = setTitleMapAction.payload,
            path = _setTitleMapAction$pa.path,
            title = _setTitleMapAction$pa.title;
        var items = state;
        var index = items.findIndex(function (value) {
          return value.path === path;
        });

        if (index !== -1) {
          items[index] = Object.assign({}, items[index], {
            name: title
          });
        }

        return items;
      }

    default:
      return state;
  }
}