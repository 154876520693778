import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _last from "lodash/last";
import moment from 'moment';
export function getTimeUnit(min, max) {
  if (min && max) {
    var duration = moment.duration(max.diff(min));
    var hours = duration.asHours();

    if (hours < 1) {
      return 'second';
    } else if (hours < 2) {
      return 'minute';
    } else if (hours < 24) {
      return 'hour';
    }

    if (hours >= 24 * 7 * 5) {
      return 'week';
    } else if (hours >= 24 * 7) {
      return 'day';
    } else if (hours >= 24) {
      return 'hour';
    }
  }

  return 'hour';
}
export function getTimeAxis(unit, min, max, fontColor, gridColor, stepSize) {
  if (unit === void 0) {
    unit = 'minute';
  }

  if (stepSize === void 0) {
    stepSize = 1;
  }

  return {
    id: 'time-axis',
    display: true,
    type: 'time',
    time: {
      unit: unit,
      stepSize: stepSize,
      round: false,
      tooltipFormat: 'LTS',
      displayFormats: {
        millisecond: 'HH:mm:ss.SSS',
        second: 'HH:mm:ss',
        minute: 'LT L',
        hour: 'LT L',
        day: 'MMM D',
        week: 'll',
        month: 'MMM YYYY',
        quarter: 'MMM YYYY',
        year: 'YYYY'
      }
    },
    bounds: 'ticks',
    ticks: {
      maxRotation: 90,
      minRotation: 30,
      fontSize: 12,
      padding: 35,
      minor: {
        fontSize: 12
      },
      mayor: {
        enabled: true,
        fontSize: 14
      },
      min: min,
      max: max,
      fontColor: fontColor
    },
    gridLines: {
      display: true,
      tickMarkLength: 15,
      color: gridColor
    }
  };
}
export function getYAxis(yAxis, idx) {
  return {
    type: 'linear',
    tension: 0,
    display: !!(yAxis || {}).id,
    position: idx % 2 === 0 ? 'left' : 'right',
    id: (yAxis || {}).id,
    scaleLabel: {
      padding: 15,
      fontColor: (yAxis || {}).color,
      labelString: (yAxis || {}).caption,
      display: true
    },
    ticks: {
      labelOffset: 0,
      padding: 0,
      fontColor: (yAxis || {}).color,
      callback: function callback(value, index, values) {
        // don´t draw the value in the center: saves space and to prevents overlaypping of tick labels and yAxis label
        var isCenter = Math.floor(values.length / 2) === index;
        return isCenter ? '' : value;
      }
    },
    gridLines: {
      color: '#C8C8C8'
    }
  };
}
export function addGapMarkers(data) {
  // if data points are more than 2 hours apart => inset null data
  return data.reduce(function (list, current, index) {
    var previous = _last(list);

    if (previous && previous.x !== undefined && previous.x !== null && current.x !== undefined && current.x !== null) {
      var currentMoment = moment(current.x);
      var previousMoment = moment(previous.x);
      var gapDuration = moment.duration(currentMoment.diff(previousMoment));
      var gap = gapDuration.asHours();

      if (gap > 2) {
        return [].concat(_toConsumableArray(list), [{
          x: current.x,
          y: null
        }, current]);
      }
    }

    return [].concat(_toConsumableArray(list), [current]);
  }, []);
}