import { SortingOrder } from '@km/components/dashboard/list/reducers/SortingOrder';
export var initialSortingState = {
  key: 'name',
  order: SortingOrder.Asc
};
export function getInitialSortingState(sortingState) {
  return sortingState !== null && sortingState !== void 0 ? sortingState : initialSortingState;
}
var SetSortingActionType = 'SET_SORTING';
export function processSortingReducer(state, action) {
  switch (action.type) {
    case SetSortingActionType:
      {
        var param = action.payload.param;

        if (state.key !== param) {
          return {
            key: param,
            order: SortingOrder.Asc
          };
        }

        return {
          key: param,
          order: state.order === SortingOrder.Asc ? SortingOrder.Desc : SortingOrder.Asc
        };
      }

    default:
      throw new Error("Unknown action type: " + action.type);
  }
}
export function setSorting(key) {
  return {
    type: SetSortingActionType,
    payload: {
      param: key
    }
  };
}