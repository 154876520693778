import { sortByDeviceNameASC, sortByDeviceNameDSC, sortByStabilityASC, sortByStabilityDSC, sortByStatusASC, sortByStatusDSC } from './machinesort';
import { MachinesSortingOrder } from '@km/utils/MachinesSortingOrder';
export var MACHINE_SORT_KEYS = {
  NAME_ASC: 'name-asc',
  NAME_DSC: 'name-dsc',
  STATUS_ASC: 'stat-asc',
  STATUS_DSC: 'stat-dsc',
  RUNNING_ASC: 'run-asc',
  RUNNING_DSC: 'run-dsc',
  STABILITY_ASC: 'stab-asc',
  STABILITY_DSC: 'stab-dsc',
  PRODUCTIVITY_ASC: 'prod-asc',
  PRODUCTIVITY_DSC: 'prod-dsc'
};
var sortOptions = [{
  key: MachinesSortingOrder.NAME_ASC,
  fn: sortByDeviceNameASC
}, {
  key: MachinesSortingOrder.NAME_DSC,
  fn: sortByDeviceNameDSC
}, {
  key: MachinesSortingOrder.ONLINE,
  fn: sortByStatusASC
}, {
  key: MachinesSortingOrder.OFFLINE,
  fn: sortByStatusDSC
}, {
  key: MachinesSortingOrder.STABILITY_ASC,
  fn: sortByStabilityASC
}, {
  key: MachinesSortingOrder.STABILITY_DSC,
  fn: sortByStabilityDSC
}];