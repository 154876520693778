import _isNil from "lodash/isNil";
import { useEffect, useState } from 'react';
import { fetchMachineRawData } from '@km/api/service/machine-data/fetchMachineRawData';
import { getLogger } from '@km/utils/logger';
import { useCurrentTenant } from '../useCurrentTenant';
var logger = getLogger('useMachineInformation');
export function useMachineInformation(deviceId, parameters) {
  var _useState = useState(null),
      machineInfo = _useState[0],
      setMachineInfo = _useState[1];

  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  useEffect(function () {
    if (!_isNil(currentTenant)) {
      fetchMachineRawData({
        Topic: 'machine_info',
        DeviceId: deviceId,
        Attributes: ['ContrType', 'ContrVersion', 'MachDesc', 'MachNbr', 'MachVendor', 'MachName'],
        NextToken: '',
        Limit: 1
      }, currentTenant.GraphqlEndpoint, parameters).then(function (data) {
        var parsedData = JSON.parse(data.RawData);
        setMachineInfo(parsedData);
      }).catch(function (err) {
        return logger.error(err);
      });
    }
  }, [currentTenant]);
  return machineInfo;
}