import { useContext } from 'react';
import { PostContext } from '@km/hooks/posts/PostContext';
export function useNotifications() {
  var _postContext$notifica;

  var postContext = useContext(PostContext);
  return {
    areNotificationsEnabled: postContext === null || postContext === void 0 ? void 0 : postContext.areNotificationsEnabled,
    notifications: (_postContext$notifica = postContext === null || postContext === void 0 ? void 0 : postContext.notifications) !== null && _postContext$notifica !== void 0 ? _postContext$notifica : [],
    setNotifications: postContext === null || postContext === void 0 ? void 0 : postContext.setNotifications,
    setAreNotificationsEnabled: postContext === null || postContext === void 0 ? void 0 : postContext.setAreNotificationsEnabled
  };
}