import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import _isEmpty from "lodash/isEmpty";
import _last from "lodash/last";
import _groupBy from "lodash/groupBy";
//@ts-nocheck
import moment from 'moment';
import { PostCreatorType } from '@km/api/model/PostCreatorTypes';
export function groupByDate(posts) {
  var groupedPosts = _groupBy(posts, function (post) {
    return Math.floor(moment.duration(post.Timestamp, 'seconds').asDays());
  });

  return groupedPosts;
}

var isUserPost = function isUserPost(post) {
  return post.Creator && post.Creator.Type === PostCreatorType.USER;
};

var listContainsSameType = function listContainsSameType(list, post) {
  return list.every(function (p) {
    var _p$Creator, _post$Creator;

    return p.PostType === post.PostType && ((_p$Creator = p.Creator) === null || _p$Creator === void 0 ? void 0 : _p$Creator.Type) === ((_post$Creator = post.Creator) === null || _post$Creator === void 0 ? void 0 : _post$Creator.Type);
  });
};

var sortAscending = function sortAscending(a, b) {
  return a.Timestamp - b.Timestamp;
};

var sortDescending = function sortDescending(a, b) {
  return b.Timestamp - a.Timestamp;
};

export function groupConsecutiveTypes(posts, sort) {
  if (sort === void 0) {
    sort = 'asc';
  }

  var sortFn = sort === 'asc' ? sortAscending : sortDescending;
  var grouped = posts.sort(sortFn).reduce(function (lists, post) {
    // get the current post list
    var currentGroup = _last(lists);

    var isFromUser = isUserPost(post);
    var containsSameType = listContainsSameType(currentGroup, post);

    if (isFromUser) {
      return [].concat(_toConsumableArray(lists), [[post]]);
    }

    if (_isEmpty(currentGroup) || containsSameType) {
      // => add post to list
      currentGroup.push(post);
      return lists;
    } else {
      //else make new list and add post
      return [].concat(_toConsumableArray(lists), [[post]]);
    }
  }, [[]]).filter(function (item) {
    return item.length > 0;
  }).reduce(function (list, group) {
    return group.length === 1 ? [].concat(_toConsumableArray(list), _toConsumableArray(group)) : [].concat(_toConsumableArray(list), [group.sort(sortFn)]);
  }, []);
  return grouped;
}
export function groupByDateAndConsecutiveTypes(posts, sort) {
  if (sort === void 0) {
    sort = 'asc';
  }

  var groupedByDate = groupByDate(posts);
  var map = Object.keys(groupedByDate).reduce(function (map, key) {
    var _Object$assign;

    var dateGroup = groupedByDate[key];
    return Object.assign({}, map, (_Object$assign = {}, _Object$assign[key] = groupConsecutiveTypes(dateGroup, sort), _Object$assign));
  }, {});
  return map;
}