import { getLogger } from '@km/utils/logger';
import { selectAttributeValues } from '@km/state/machinedata/selectors';
import { addGapMarkers } from '@km/utils/chart';
var logger = getLogger('useMachineData');
export function extractMachineData(params, machineRawDataModel) {
  var DeviceId = params.DeviceId,
      _params$tokens = params.tokens,
      tokens = _params$tokens === void 0 ? [] : _params$tokens,
      From = params.From,
      To = params.To;

  if (!machineRawDataModel) {
    return {
      data: []
    };
  }

  var attributeValues = selectAttributeValues(DeviceId, tokens, From.unix(), To.unix(), undefined, machineRawDataModel);
  logger.debug('values from store:', attributeValues);
  logger.debug('attributeValues', attributeValues, tokens);
  var chartDataSets = tokens.map(function (token) {
    var values = attributeValues[token] || [];
    return {
      key: token,
      chartData: addGapMarkers(values.filter(function (value) {
        return value[token] !== undefined;
      }).map(function (value) {
        return {
          x: value.TimestampEdge * 1000,
          y: value[token] === undefined ? null : value[token]
        };
      }).sort(function (a, b) {
        return a.x - b.x;
      }))
    };
  });
  return {
    data: chartDataSets
  };
}