import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import mapValues from 'lodash/mapValues';
import keyBy from 'lodash/keyBy';
import isNil from 'lodash/isNil';
export var isBetweenOrAtEdge = function isBetweenOrAtEdge(value, from, to) {
  return value >= from && value <= to;
};
export var isBetween = function isBetween(value, from, to) {
  return value > from && value < to;
};
export var isBeforeOrSame = function isBeforeOrSame(value, ts) {
  return value <= ts;
};
export var hasOverlap = function hasOverlap(chunkA, chunkB) {
  return isBetweenOrAtEdge(chunkA.from, chunkB.from, chunkB.to) || isBetweenOrAtEdge(chunkA.to, chunkB.from, chunkB.to) || isBetweenOrAtEdge(chunkB.to, chunkA.from, chunkA.to) || isBetweenOrAtEdge(chunkB.from, chunkA.from, chunkA.to);
};
/**
 * 1. sort the chunks by their from value
 * 2. walk the array and compare each chunk´s from and to if the previous range touches or connects
 *  => yes: continue, no: stop
 *
 * .|----------|..........
 * ...|----|..........
 * .....|----|..........
 * ........|-----|..........
 * ..............|----------|..........
 *
 */

export var chunksAreConnected = function chunksAreConnected(chunks) {
  return chunks.concat().sort(function (a, b) {
    return a.from - b.from;
  }).reduce(function (range, chunk) {
    if (range.from === null && range.to === null) {
      return {
        from: chunk.from,
        to: chunk.to,
        isConnected: true
      };
    }

    if (range && range.isConnected && !isNil(range.from) && !isNil(range.to)) {
      var isConnected = isBetweenOrAtEdge(chunk.from, range.from, range.to);
      var from = Math.min(range.from, chunk.from);
      var to = Math.max(range.to, chunk.to);
      return {
        from: from,
        to: to,
        isConnected: range.isConnected && isConnected
      };
    }

    return Object.assign({}, range, {
      isConnected: false
    });
  }, {
    from: null,
    to: null,
    isConnected: true
  }).isConnected;
};
/**
 * Merges the two chunks A and B.
 * The new chunks will contain all values from A and B. Values in B will override values from A if they have the same key.
 * The new chunk´s from and to properties are determined by Math.min(A.from, B.from) and Math.max(A.to, B.to).
 * If defined the name of A will be used otherwise the name of B.
 */

export var mergeChunk = function mergeChunk(chunkA, chunkB) {
  var from = Math.min(chunkA.from, chunkB.from);
  var to = Math.max(chunkA.to, chunkB.to);
  var values = Object.assign({}, chunkA.values, chunkB.values);
  var count = Object.keys(values).length;
  var result = {
    from: from,
    to: to,
    values: values,
    count: count,
    name: chunkA.name || chunkB.name
  };
  return result;
};
/**
 * Merges all given chunks into one by using mergeChunk.
 */

export var mergeChunks = function mergeChunks(chunks) {
  return chunks.reduce(function (result, chunk) {
    return mergeChunk(result, chunk);
  }, {
    name: '',
    from: Infinity,
    to: -Infinity,
    values: {},
    count: 0
  });
};
/**
 * Merges all connecting chunks into one.
 */

export function consolidateChunks(chunks) {
  /**
   * merge chunks that have overlapping time frames
   */
  return chunks.concat().sort(function (a, b) {
    return a.from - b.from;
  }).reduce(function (result, chunk) {
    var overlaps = result.filter(function (ch) {
      return hasOverlap(ch, chunk);
    });

    if (overlaps.length) {
      var mergedChunk = mergeChunks([].concat(_toConsumableArray(overlaps), [chunk]));
      return [].concat(_toConsumableArray(result.filter(function (ch) {
        return !overlaps.includes(ch);
      })), [mergedChunk]);
    }

    return [].concat(_toConsumableArray(result), [chunk]);
  }, []);
}
export function getChunkTimestamps(chunks) {
  if (chunks === void 0) {
    chunks = [];
  }

  var values = chunks.map(function (chunk) {
    return chunk.values;
  }).reduce(function (map, values) {
    return Object.assign({}, map, values);
  }, {});
  var timestamps = Object.keys(values).map(function (key) {
    return parseFloat(key);
  }).sort(function (a, b) {
    return a - b;
  });
  return {
    timestamps: timestamps,
    values: values
  };
}
export function truncateChunk(chunk, fromTs, toTs) {
  if (toTs === void 0) {
    toTs = chunk.to;
  }

  // remove all values older that fromTs and newer than toTs
  var values = Object.entries(chunk.values).reduce(function (result, _ref) {
    var key = _ref[0],
        value = _ref[1];
    var ts = parseFloat(key);

    if (ts >= fromTs && ts <= toTs) {
      var _ref2;

      return _ref2 = {}, _ref2[key] = value, _ref2;
    }

    return result;
  }, {});
  return Object.assign({}, chunk, {
    from: fromTs,
    to: toTs,
    values: values
  });
}
export function truncateValueStoreSlice(slice, fromTs, toTs) {
  var chunks = slice.chunks.map(function (chunk) {
    return truncateChunk(chunk, fromTs, toTs);
  });
  return Object.assign({}, slice, {
    latestTo: toTs || slice.latestTo,
    chunks: chunks
  });
}
export function truncateValueStore(store, fromTs, toTs) {
  var entries = Object.entries(store);
  return mapValues(keyBy(entries, function (entry) {
    return entry[0];
  }), function (entry) {
    return truncateValueStoreSlice(entry[1], fromTs, toTs);
  });
}