import "core-js/modules/es.array.reduce";
import _objectWithoutPropertiesLoose from "@babel/runtime/helpers/esm/objectWithoutPropertiesLoose";
import mergeWith from 'lodash/mergeWith';
import isNil from 'lodash/isNil';
import { useFetchMachineList } from '@km/hooks/machines/useFetchMachineList';
import { useEffect, useState } from 'react';
export function useMachines(parameters) {
  var _useState = useState({}),
      machinesMap = _useState[0],
      setMachinesMap = _useState[1];

  var _useFetchMachineList = useFetchMachineList(parameters),
      machines = _useFetchMachineList.machines,
      fetchMachinesListRestResult = _objectWithoutPropertiesLoose(_useFetchMachineList, ["machines"]);

  useEffect(function () {
    setMachinesMap(machines.reduce(function (acc, item) {
      var _Object$assign;

      return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[item.DeviceId] = item, _Object$assign));
    }, {}));
  }, [JSON.stringify(machines)]);

  var updateMachineData = function updateMachineData(machineData) {
    var _Object$assign2;

    var DeviceId = machineData.DeviceId;
    var machine = machinesMap[DeviceId];
    setMachinesMap(Object.assign({}, machinesMap, (_Object$assign2 = {}, _Object$assign2[DeviceId] = mergeWith(machine, machineData, mergeMachineDataFn), _Object$assign2)));
  };

  var getMachine = function getMachine(deviceId) {
    var _machinesMap$deviceId;

    return (_machinesMap$deviceId = machinesMap[deviceId]) !== null && _machinesMap$deviceId !== void 0 ? _machinesMap$deviceId : null;
  };

  return Object.assign({
    machines: Object.values(machinesMap),
    updateMachineData: updateMachineData,
    getMachine: getMachine
  }, fetchMachinesListRestResult);
}

function mergeMachineDataFn(objValue, srcValue) {
  if (isNil(srcValue)) {
    return objValue;
  }

  return srcValue;
}