export var MachinesSortingOrder;

(function (MachinesSortingOrder) {
  MachinesSortingOrder["NAME_ASC"] = "name-asc";
  MachinesSortingOrder["NAME_DSC"] = "name-dsc";
  MachinesSortingOrder["ONLINE"] = "status-online";
  MachinesSortingOrder["OFFLINE"] = "status-offline";
  MachinesSortingOrder["STABILITY_ASC"] = "stab-asc";
  MachinesSortingOrder["STABILITY_DSC"] = "stab-dsc";
  MachinesSortingOrder["PRODUCTIVITY_ASC"] = "prod-asc";
  MachinesSortingOrder["PRODUCTIVITY_DSC"] = "prod-dsc";
})(MachinesSortingOrder || (MachinesSortingOrder = {}));