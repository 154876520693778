import "core-js/modules/es.promise.finally";
import _isNull from "lodash/isNull";
import { logout } from '@km/api/service/auth/auth';
import { fetchMaintenance } from '@km/api/service/maintenance/fetchMaintenance';
import { isMaintenanceSelector } from '@km/state/maintenance/selectors/isMaintenanceSelector';
import { setMaintenanceAction } from '@km/state/maintenance/setMaintenanceAction';
import { getLogger } from '@km/utils/logger';
import { navigate } from '@reach/router';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
var logger = getLogger('useCheckMaintenanceMode');
export function useCheckMaintenanceMode() {
  var dispatch = useDispatch();
  var maintenanceState = useSelector(isMaintenanceSelector);

  var _useState = useState(moment()),
      lastChecked = _useState[0],
      setLastChecked = _useState[1];

  var _useState2 = useState(null),
      maintenaceMode = _useState2[0],
      setMaintenanceMode = _useState2[1];

  var retry = function retry() {
    setMaintenanceMode(null);
  };

  useEffect(function () {
    if (_isNull(maintenaceMode)) {
      fetchMaintenance().then(function (result) {
        var underMaintenance = result.UnderMaintenance;
        /**
         * If application switches maintenance back from "is under maintenance" to "normal" state -> log the user out
         * Else stay logged in and display the maintenance mode screen
         * - We need to stay logged in because otherwise we can not make requests to the global API
         */

        if (maintenanceState === true && underMaintenance === false) {
          dispatch(setMaintenanceAction(underMaintenance));
          logout();
        } else {
          if (underMaintenance) {
            dispatch(setMaintenanceAction(underMaintenance));
            navigate('/maintenance');
          }

          setMaintenanceMode(underMaintenance);
        }
      }).catch(function (err) {
        logger.error(err);
      }).finally(function () {
        setLastChecked(moment());
      });
    }
  }, [maintenaceMode]);
  return {
    isMaintenaceMode: maintenaceMode,
    lastChecked: lastChecked,
    retry: retry
  };
}