import "core-js/modules/es.array.reduce";
import "core-js/modules/es.promise.finally";
import _isNil from "lodash/isNil";
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
import { fetchGlobalTenantDevices } from '@km/api/service/machine/fetchGlobalTenantDevices';
import { useCurrentTenant } from '@km/hooks/useCurrentTenant';
import { useMachineRawDataAttributes } from '@km/hooks/machines/useMachineRawDataAttributes';
import { useMachineRawData } from '@km/hooks/machines/useMachineRawData';
import { isRequestInProgress } from '@km/utils/RequestStatus';
var logger = getLogger('useFetchMachineList');
export function useDevices() {
  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  var _useState = useState(null),
      devices = _useState[0],
      setDevices = _useState[1];

  var _useState2 = useState([]),
      deviceIds = _useState2[0],
      setDeviceIds = _useState2[1];

  var _useState3 = useState(true),
      isLoading = _useState3[0],
      setIsLoading = _useState3[1];

  var _useMachineRawDataAtt = useMachineRawDataAttributes('condition_monitoring', deviceIds, undefined, !_isNil(devices)),
      fetchAttributesStatus = _useMachineRawDataAtt[0],
      attributesMap = _useMachineRawDataAtt[1];

  var _useMachineRawData = useMachineRawData('condition_monitoring', deviceIds, mapAttributes(attributesMap), !isRequestInProgress(fetchAttributesStatus), undefined, undefined, 1),
      rawDataFetchStatus = _useMachineRawData[0],
      conditionMonitoringRawData = _useMachineRawData[1];

  var refetchDevices = function refetchDevices() {
    setDevices(null);
  };

  var getDeviceIds = function getDeviceIds(machines) {
    setDeviceIds(machines.map(function (machine) {
      return machine.DeviceId;
    }));
  };

  useEffect(function () {
    if (!_isNil(currentTenant)) {
      setIsLoading(true);
      var tenantId = currentTenant.TenantId;

      if (_isNil(devices)) {
        fetchGlobalTenantDevices({
          Limit: 1000,
          TenantId: tenantId
        }).then(function (result) {
          var deviceList = result.Items;
          setDevices(deviceList);
          getDeviceIds(deviceList);
        }).catch(function (err) {
          logger.error(err);
        }).finally(function () {
          return setIsLoading(false);
        });
      }
    }
  }, [JSON.stringify(devices !== null && devices !== void 0 ? devices : []), currentTenant]);
  return {
    devices: devices,
    deviceIds: deviceIds,
    isFetchingDevices: isLoading || isRequestInProgress(fetchAttributesStatus) || isRequestInProgress(rawDataFetchStatus),
    refetchDevices: refetchDevices,
    attributesMap: attributesMap,
    conditionMonitoringRawData: conditionMonitoringRawData
  };
}

function mapAttributes(attr) {
  return Object.entries(attr).filter(function (_ref) {
    var id = _ref[0],
        value = _ref[1];
    return !_isNil(value === null || value === void 0 ? void 0 : value.attributes) && Object.keys(value.attributes).some(function (attribute) {
      return ['CmNrvStat_1', 'CmScrStat_1'].includes(attribute);
    });
  }).reduce(function (acc, _ref2) {
    var _Object$assign;

    var id = _ref2[0],
        value = _ref2[1];
    return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[id] = Object.keys(value.attributes), _Object$assign));
  }, {});
}