import "core-js/modules/es.array.reduce";
export function getChartSettings(points) {
  var chartSettings = points.reduce(function (acc, value) {
    var y = value.y;

    if (!acc.min && !acc.max) {
      acc.min = y;
      acc.max = y;
    } else {
      acc.min = acc.min < y ? acc.min : y;
      acc.max = acc.max > y ? acc.max : y;
    }

    return acc;
  }, {});
  return {
    max: chartSettings.max,
    min: chartSettings.min
  };
}