var cache = new Map();
var colors = ['#2b8c78', '#bbc05b', '#de5050', '#9d789b', '#63cfb9', '#6b6e2b', '#eb9494', '#684b66', '#9de1d4', '#ab2121'];
export function getColor(key) {
  var color = cache.get(key);

  if (!color) {
    var newColor = colors[cache.size] || '#000000';
    cache.set(key, newColor);
    return newColor;
  }

  return color;
}