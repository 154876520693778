import { selectPostsBetween } from '@km/state/unreadMessages/selectors/selectors';
import { getLocaleFromCode } from '@km/utils/getLocale';
import { useTranslation } from 'react-i18next';
import { RequestStatus } from '@km/utils/RequestStatus';
import { useContext, useEffect } from 'react';
import { PostContext } from '@km/hooks/posts/PostContext';
export function usePostsBetween(DeviceId, from, to, fetchAll, limit, canStartFetch) {
  var _postsContextData$pos, _postsContextData$pos2;

  if (fetchAll === void 0) {
    fetchAll = false;
  }

  if (canStartFetch === void 0) {
    canStartFetch = true;
  }

  var _useTranslation = useTranslation(),
      i18n = _useTranslation.i18n;

  var postsContextData = useContext(PostContext);
  useEffect(function () {
    if (canStartFetch) {
      postsContextData === null || postsContextData === void 0 ? void 0 : postsContextData.updatePostConfig({
        DeviceId: DeviceId,
        locale: getLocaleFromCode(i18n.language),
        amount: limit,
        fromUnix: from.unix(),
        toUnix: to.unix(),
        append: true,
        applyFilters: true,
        fetchAll: fetchAll
      });
    }
  }, [DeviceId, to.unix(), from.unix(), limit, fetchAll, canStartFetch]);

  var _selectPostsBetween = selectPostsBetween((_postsContextData$pos = postsContextData === null || postsContextData === void 0 ? void 0 : postsContextData.postsData.posts) !== null && _postsContextData$pos !== void 0 ? _postsContextData$pos : [], from, to, [DeviceId]),
      _selectPostsBetween$D = _selectPostsBetween[DeviceId],
      posts = _selectPostsBetween$D === void 0 ? [] : _selectPostsBetween$D;

  return {
    requestStatus: (_postsContextData$pos2 = postsContextData === null || postsContextData === void 0 ? void 0 : postsContextData.postsData.requestStatus) !== null && _postsContextData$pos2 !== void 0 ? _postsContextData$pos2 : RequestStatus.Error,
    posts: posts
  };
}