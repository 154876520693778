import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { createMuiTheme } from '@material-ui/core/styles';
import DinProMedium from '@assets/font/DINProMedium.woff';
import DinProLight from '@assets/font/DINProLight.woff2';
var fontMedium = {
  fontFamily: 'DinProMedium',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: "\n            local('DinProMedium'),\n            url(" + DinProMedium + ") format('woff')\n          "
};
var fontLight = {
  fontFamily: 'DinProLight',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 'normal',
  src: "local('DinProLight'),\n        url(" + DinProLight + ") format('woff2')\n        "
};
var customPalette = {
  text: {
    primary: '#00325A'
  },
  primary: {
    main: '#0278C9',
    light: '#0077c9',
    dark: '#00325A'
  },
  secondary: {
    main: '#FF8300',
    light: '#2B8C78'
  },
  error: {
    main: '#DE5050'
  },
  neutral: {
    main: '#7D7D7D',
    dark: '#212121',
    light: '#C8C8C8',
    contrastText: '#F5F5F5'
  },
  background: {
    default: '#ffffff'
  }
};
export var Theme = createMuiTheme({
  typography: {
    fontSize: 15,
    htmlFontSize: 15,
    fontFamily: 'DinProLight',
    h1: {
      fontFamily: 'DinProMedium',
      color: 'white',
      fontSize: '1.71rem'
    },
    h5: {
      fontFamily: 'DinProMedium'
    }
  },
  shape: {
    borderRadius: 3
  },
  shadows: ['none', '0 0 3px 0 rgba(0,0,0,0.3)', '0 0 5px 0 rgba(33,33,33,0.25)', '0 0 5px 0 rgba(33,33,33,0.25)', '0 0 5px 2px rgba(33,33,33,0.35)'].concat(_toConsumableArray(new Array(23).fill('none'))),
  background: {
    default: 'white'
  },
  props: {
    MuiInputLabel: {
      color: 'secondary'
    },
    MuiTextField: {
      fullWidth: true
    }
  },
  palette: Object.assign({}, customPalette),
  overrides: {
    typography: {
      fontSize: 15,
      htmlFontSize: 15
    },
    MuiLink: {
      root: {
        fontFamily: 'DinProMedium',
        '&:hover': {
          color: customPalette.secondary.main
        }
      }
    },
    MuiButton: {
      root: {
        fontSize: '1rem',
        fontFamily: 'DinProMedium'
      },
      label: {
        textTransform: 'none'
      }
    },
    MuiInput: {
      underline: {
        color: "" + customPalette.primary.dark,
        '&::after': {
          borderColor: customPalette.secondary.main
        },
        '&::before': {
          borderBottom: "1px solid " + customPalette.neutral.main
        }
      }
    },
    MuiCssBaseline: {
      '@global': {
        // @ts-ignore
        '@font-face': [fontMedium, fontLight],
        html: {
          height: '100%'
        },
        body: {
          height: '100%'
        }
      }
    }
  },
  spacing: function spacing(factor) {
    return 0.25 * factor + "rem";
  }
});