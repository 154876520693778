import isNil from 'lodash/isNil';
import { getWindow, isBrowser } from '@km/utils/globalWindow';
import { navigate } from '@reach/router';
import { LocalStorageService } from '@km/utils/LocalStorageService';
import Bugsnag from '@bugsnag/js';
import { DbService } from '@km/db/DbService';
import { getLogger } from '@km/utils/logger';
import Auth from '@aws-amplify/auth';
var logger = getLogger('auth');
var globalWindow = getWindow();
export var setUser = function setUser(user) {
  globalWindow.localStorage.kmuser = JSON.stringify(Object.assign({}, user));
};
export var isLoggedIn = function isLoggedIn(user) {
  if (!isBrowser() || isNil(user)) return false;
  Bugsnag.setUser(user.UserId, user.UserEmail, user.Firstname + " " + user.Lastname);
  Bugsnag.addMetadata('Tenant', {
    TenantIds: user.TenantIds
  });
  return !isNil(user);
};
var preLogoutTasks = [];
export var addPreLogoutTask = function addPreLogoutTask(task) {
  preLogoutTasks.push(task);
};
export var logout = function logout(redirectTo) {
  if (!isBrowser()) return Promise.resolve(); //const dispatch = useDispatch();

  return Promise.all(preLogoutTasks.map(function (task) {
    return task();
  })).then(function () {
    //clear all prelogout tasks
    preLogoutTasks.splice(0, preLogoutTasks.length);
  }).then(function () {
    return Auth.signOut();
  }).then(function () {
    clearStorage();
    DbService.destroy();
  }).then(function () {
    navigate('/login');
  }).catch(function (err) {
    return logger.error(err);
  });
};

var clearStorage = function clearStorage() {
  LocalStorageService.getService().clear();
};