var _DURATIONS;

import moment from 'moment';
export var DURATION_KEYS = {
  lastHour: 'l1h',
  last3Hours: 'l3h',
  last8Hours: 'l8h',
  last24Hours: 'l24h',
  last2Days: 'l2d',
  last3Days: 'l3d',
  last5Days: 'l5d',
  last7Days: 'l7d',
  lastWeek: 'lw',
  last2Weeks: 'l2w',
  last3Weeks: 'l3w',
  lastMonth: 'lm',
  last6Month: 'l6m',
  last12Month: 'l12m'
};
export var DURATIONS = (_DURATIONS = {}, _DURATIONS[DURATION_KEYS.lastHour] = moment.duration(1, 'hours'), _DURATIONS[DURATION_KEYS.last3Hours] = moment.duration(3, 'hours'), _DURATIONS[DURATION_KEYS.last8Hours] = moment.duration(8, 'hours'), _DURATIONS[DURATION_KEYS.last24Hours] = moment.duration(24, 'hours'), _DURATIONS[DURATION_KEYS.last2Days] = moment.duration(2, 'days'), _DURATIONS[DURATION_KEYS.last3Days] = moment.duration(3, 'days'), _DURATIONS[DURATION_KEYS.last5Days] = moment.duration(5, 'days'), _DURATIONS[DURATION_KEYS.last7Days] = moment.duration(7, 'days'), _DURATIONS[DURATION_KEYS.lastWeek] = moment.duration(2, 'weeks'), _DURATIONS[DURATION_KEYS.last2Weeks] = moment.duration(2, 'weeks'), _DURATIONS[DURATION_KEYS.last3Weeks] = moment.duration(3, 'weeks'), _DURATIONS[DURATION_KEYS.lastMonth] = moment.duration(1, 'months'), _DURATIONS[DURATION_KEYS.last6Month] = moment.duration(6, 'months'), _DURATIONS[DURATION_KEYS.last12Month] = moment.duration(12, 'months'), _DURATIONS);