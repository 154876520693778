import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
var log = console.log.bind(console);
var warn = console.warn.bind(console);
var error = console.error.bind(console);
var info = console.info.bind(console);
var debug = console.debug.bind(console); // const trace = console.trace.bind(console);

export var LOG_LEVELS = {
  ERROR: 'ERROR',
  WARN: 'WARN',
  INFO: 'INFO',
  DEBUG: 'DEBUG',
  TRACE: 'TRACE',
  LOG: 'LOG'
};
var loggers = {
  ERROR: error,
  WARN: warn,
  INFO: info,
  DEBUG: debug,
  TRACE: log,
  LOG: log
};

var makeLogger = function makeLogger(prefix, level) {
  if (process.env.NODE_ENV === 'production') {
    return function () {// Do nothing
    };
  }

  var logFn = loggers[level];
  return function () {
    for (var _len = arguments.length, messages = new Array(_len), _key = 0; _key < _len; _key++) {
      messages[_key] = arguments[_key];
    }

    logFn.apply(void 0, ["[" + prefix + "]"].concat(_toConsumableArray(messages.map(function (msg) {
      return typeof msg === 'function' ? msg() : msg;
    }))));
  };
};

export var getLogger = function getLogger(prefix) {
  return {
    info: makeLogger(prefix, 'INFO'),
    debug: makeLogger(prefix, 'DEBUG'),
    error: makeLogger(prefix, 'ERROR'),
    trace: makeLogger(prefix, 'TRACE'),
    warn: makeLogger(prefix, 'WARN'),
    log: makeLogger(prefix, 'LOG')
  };
};
export { log, warn, error, info, debug };