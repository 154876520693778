import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import React, { useState } from 'react';
import { useSnackbar } from 'notistack';
var displayed = [];
export function useNotifier() {
  var _useSnackbar = useSnackbar(),
      enqueueSnackbar = _useSnackbar.enqueueSnackbar,
      closeSnackbar = _useSnackbar.closeSnackbar;

  var _useState = useState([]),
      notifications = _useState[0],
      setNotifications = _useState[1];

  var showNotifications = function showNotifications(key, message, options) {
    setNotifications([{
      key: key,
      message: message,
      options: options
    }]);
  };

  var storeDisplayed = function storeDisplayed(id) {
    // @ts-ignore
    displayed = [].concat(_toConsumableArray(displayed), [id]);
  };

  var removeDisplayed = function removeDisplayed(id) {
    displayed = _toConsumableArray(displayed.filter(function (key) {
      return id !== key;
    }));
  };

  React.useEffect(function () {
    notifications.forEach(function (_ref) {
      var key = _ref.key,
          message = _ref.message,
          _ref$options = _ref.options,
          options = _ref$options === void 0 ? {} : _ref$options,
          _ref$dismissed = _ref.dismissed,
          dismissed = _ref$dismissed === void 0 ? false : _ref$dismissed;

      if (dismissed) {
        closeSnackbar(key);
        return;
      } // @ts-ignore


      if (displayed.includes(key)) return;
      enqueueSnackbar(message, Object.assign({
        key: key,
        id: key
      }, options, {
        onExited: function onExited(event, myKey) {
          removeDisplayed(myKey);
          setNotifications(notifications.filter(function (item) {
            return item.key !== myKey;
          }));
        }
      }));
      storeDisplayed(key);
    });
  }, [notifications, closeSnackbar, enqueueSnackbar]);
  return {
    showNotifications: showNotifications
  };
}