var _EventTypeDisplayValu;

import { PostType } from '../api/model/PostType';
/**
 * NOTE:
 * Keep the event colors in sync with the ones defined in src/shared/styles/variables.scss file.
 *
 */

/**
 * TODO: remove all references to name attribute of Types and replace with i18next translations
 */
export var EventTypeDisplayValues = (_EventTypeDisplayValu = {}, _EventTypeDisplayValu[PostType.MACHINE_ALARM] = {
  color: '#de5050'
}, _EventTypeDisplayValu[PostType.MACHINE_EVENT] = {
  color: '#ff8300'
}, _EventTypeDisplayValu[PostType.PROCESS_SUPPORT] = {
  color: '#2b8c78'
}, _EventTypeDisplayValu[PostType.USER_POST] = {
  color: '#02325a'
}, _EventTypeDisplayValu[PostType.PRODUCTION_MONITORING] = {
  color: '#02325a'
}, _EventTypeDisplayValu[PostType.CONDITION_MONITORING] = {
  color: '#af789b'
}, _EventTypeDisplayValu[PostType.MACHINE_POST] = {
  color: '#868686'
}, _EventTypeDisplayValu);