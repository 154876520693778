import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { addPreLogoutTask } from '@km/api/service/auth/auth';
import firebase from 'gatsby-plugin-firebase';
import { PostType } from '@km/api/model/PostType';
import { useLocation } from '@reach/router';
import { addTimelineNotifications, removeTimelineNotifications } from './notifications';
import { addMachineNotification } from '@km/state/notifications/actions';
import { updateUser } from '@km/api/service/user/updateUser';
import { getLogger } from '@km/utils/logger';
import { useCurrentTenant } from '@km/hooks/useCurrentTenant';
import { useIsAuthenticated } from '@km/hooks/useIsAuthenticated';
import { useCurrentUser } from '@km/hooks/useCurrentUser';
import { useAreNotificationEnabled } from '@km/hooks/useAreNotificationEnabled';
var logger = getLogger('useNotifications');
/**
 * NOTE:
 *
 * This hook should not be used like other hooks. Only use it once at the top level component.
 */

export function useNotifications(machineIds) {
  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  var _useCurrentUser = useCurrentUser(),
      currentUser = _useCurrentUser.currentUser,
      setCurrentUser = _useCurrentUser.setCurrentUser;

  var _useState = useState(''),
      token = _useState[0],
      setToken = _useState[1];

  var _useState2 = useState(false),
      initialized = _useState2[0],
      setInitialized = _useState2[1];

  var dispatch = useDispatch();
  var isAuthenticated = useIsAuthenticated(currentUser);
  var areNotificationsEnabled = useAreNotificationEnabled();
  var location = useLocation();
  var locationRef = useRef(location);
  locationRef.current = location;
  useEffect(function () {
    try {
      var messaging = firebase.messaging();

      var updateToken = function updateToken() {
        return messaging.getToken().then(setToken).catch(function (err) {
          return logger.error(err);
        });
      };

      updateToken();
      messaging.onTokenRefresh(updateToken);
      messaging.onMessage(function (message) {
        var detailRouteIsActive = locationRef.current.pathname.startsWith('/process/' + message.data.TimelineId);

        if (!detailRouteIsActive) {
          dispatch(addMachineNotification(message.data.TimelineId, message));
        }
      });
    } catch (e) {
      console.warn('could not intitialize notifcations:', e);
    }
  }, []);
  useEffect(function () {
    if (!isEmpty(token) && !initialized && isAuthenticated) {
      updateUser({
        EndpointTokens: [token]
      }).then(function (user) {
        setCurrentUser === null || setCurrentUser === void 0 ? void 0 : setCurrentUser(user);
        setInitialized(true);
      }).catch(function (err) {
        return logger.error(err);
      });
      addPreLogoutTask(function () {
        return updateUser({
          RemoveEndpointTokens: token
        });
      });
    }
  }, [token, isAuthenticated, initialized]);
  useEffect(function () {
    if (initialized && machineIds.length > 0 && isAuthenticated && currentTenant) {
      Promise.all(machineIds.map(function (id) {
        return areNotificationsEnabled ? addTimelineNotifications({
          PostType: PostType.USER_POST,
          TenantId: currentTenant.TenantId,
          TimelineId: id
        }) : removeTimelineNotifications({
          PostType: PostType.USER_POST,
          TenantId: currentTenant.TenantId,
          TimelineId: id
        });
      }));
    }
  }, [JSON.stringify(machineIds), initialized, isAuthenticated, areNotificationsEnabled, currentTenant]);
  return;
}