import { CLEAR_APP_STATE } from '@km/state/app/actions';
import { ACTION_TYPE_SET_MENU_EXPANSION } from './setMenuExpansionAction';
export var initialState = {
  isExpanded: true
};
export function menuReducer(state, action) {
  if (state === void 0) {
    state = initialState;
  }

  switch (action.type) {
    case ACTION_TYPE_SET_MENU_EXPANSION:
      {
        var setMenuExpansionAction = action;
        return Object.assign({}, state, setMenuExpansionAction.payload);
      }

    case CLEAR_APP_STATE:
      return initialState;

    default:
      return state;
  }
}