import "core-js/modules/es.array.reduce";
import { navigate } from '@reach/router';
export function syncUrl(location, searchParams, context) {
  if (context === void 0) {
    context = [];
  }

  var newUrl = Object.keys(searchParams).filter(function (key) {
    return searchParams[key];
  }).reduce(function (urlString, key, idx) {
    var queryString = searchParams[key] !== undefined ? "" + urlString + (idx > 0 ? '&' : '?') + key + "=" + searchParams[key] : urlString;
    return queryString;
  }, "/" + context.join('/'));
  var currentUrl = location.pathname + location.hash + location.search;

  if (currentUrl !== newUrl) {
    navigate(newUrl);
  }
}