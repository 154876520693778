import _isNil from "lodash/isNil";
import { getLogger } from '@km/utils/logger';
import { useEffect, useState } from 'react';
import { fetchGlobalTenantDevices } from '@km/api/service/machine/fetchGlobalTenantDevices';
import { useCurrentTenant } from '../useCurrentTenant';
import { fetchAllTenantMachines } from '@km/api/service/machine/fetchAllTenantMachines';
var logger = getLogger('useFetchMachineById');
export function useFetchMachineById(deviceId, parameters) {
  var _useCurrentTenant = useCurrentTenant(),
      currentTenant = _useCurrentTenant.currentTenant;

  var _useState = useState(null),
      devices = _useState[0],
      setDevices = _useState[1];

  var _useState2 = useState(null),
      machines = _useState2[0],
      setMachines = _useState2[1];

  var _useState3 = useState(null),
      mergedMachine = _useState3[0],
      setMergeMachine = _useState3[1];

  var refetchMachine = function refetchMachine() {
    setDevices(null);
    setMachines(null);
  };

  useEffect(function () {
    if (!_isNil(currentTenant)) {
      var tenantId = currentTenant.TenantId;
      var graphQlEndpoint = currentTenant.GraphqlEndpoint;

      if (_isNil(devices)) {
        fetchGlobalTenantDevices({
          Limit: 1000,
          TenantId: tenantId
        }).then(function (result) {
          setDevices(result.Items);
        }).catch(function (err) {
          logger.error(err);
        });
      }

      if (devices && _isNil(machines)) {
        fetchAllTenantMachines({
          DeviceIds: [deviceId]
        }, graphQlEndpoint, parameters).then(function (result) {
          setMachines(result.Items);
        }).catch(function (err) {
          logger.error(err);
        });
      }
    }

    if (devices && machines) {
      var deviceToMerge = devices.filter(function (machine) {
        return deviceId === machine.DeviceId;
      });
      var machine = machines.filter(function (item) {
        return deviceId === item.DeviceId;
      });

      if (deviceToMerge && deviceToMerge.length > 0 && machine && machine.length > 0) {
        setMergeMachine(Object.assign({}, deviceToMerge[0], machine[0]));
      }
    }
  }, [devices, machines, currentTenant]);
  return {
    machine: mergedMachine,
    refetchMachine: refetchMachine
  };
}