import "core-js/modules/es.array.reduce";
import _toConsumableArray from "@babel/runtime/helpers/esm/toConsumableArray";
import { useMemo } from 'react';
import isNil from 'lodash/isNil';
import { useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
var CMStatusSteps = {
  healthy: 67,
  attention: 33
};
export function useMachinesData(devices, conditionMonitoringRawData, attributesMap) {
  var theme = useTheme();

  var _useTranslation = useTranslation(),
      t = _useTranslation.t;

  var machinesData = useMemo(function () {
    var _devices$reduce;

    return (_devices$reduce = devices === null || devices === void 0 ? void 0 : devices.reduce(function (acc, device) {
      var _attributesMap$Device, _attributesMap$Device2, _Object$assign;

      var DeviceId = device.DeviceId;
      var machineAttrData = conditionMonitoringRawData[DeviceId];

      if (isNil(machineAttrData)) {
        return acc;
      }

      var cmAttributes = getConditionMonitoringAttributes((_attributesMap$Device = (_attributesMap$Device2 = attributesMap[DeviceId]) === null || _attributesMap$Device2 === void 0 ? void 0 : _attributesMap$Device2.attributes) !== null && _attributesMap$Device !== void 0 ? _attributesMap$Device : {});
      var rawDataValues = getRawDataValues(cmAttributes, machineAttrData, theme, t);

      var _getWorstColor = getWorstColor(rawDataValues, theme),
          value = _getWorstColor[0],
          color = _getWorstColor[1];

      return Object.assign({}, acc, (_Object$assign = {}, _Object$assign[DeviceId] = {
        value: value,
        color: color,
        rawDataValues: rawDataValues
      }, _Object$assign));
    }, {})) !== null && _devices$reduce !== void 0 ? _devices$reduce : {};
  }, [devices === null || devices === void 0 ? void 0 : devices.length, JSON.stringify(attributesMap), JSON.stringify(conditionMonitoringRawData)]);
  return machinesData;
}

function getConditionMonitoringAttributes(attributes) {
  var _ref;

  return _ref = {}, _ref['CmNrvStat_1'] = attributes['CmNrvStat_1'], _ref['CmScrStat_1'] = attributes['CmScrStat_1'], _ref;
}

function getWorstColor(data, theme) {
  var _worstData$color;

  var worstData = Object.values(data).reduce(function (acc, item) {
    if (isNil(item)) {
      return acc;
    }

    var value = item.value,
        color = item.color;

    if (isNil(acc.value)) {
      return {
        value: value,
        color: getColor(value, theme)
      };
    }

    if (!isNil(value) && value < acc.value) {
      return {
        value: value,
        color: color
      };
    }

    return acc;
  }, {
    value: null,
    color: null
  });
  return [worstData.value, (_worstData$color = worstData === null || worstData === void 0 ? void 0 : worstData.color) !== null && _worstData$color !== void 0 ? _worstData$color : theme.palette.neutral.main];
}

function getRawDataValues(attributes, rawData, theme, t) {
  return Object.keys(attributes).reduce(function (acc, key) {
    var attributeRawData = rawData === null || rawData === void 0 ? void 0 : rawData[key];

    if (isNil(attributeRawData)) {
      return acc;
    }

    var data = attributeRawData.chunks[0].values;
    var timestamp = Object.keys(data)[0];
    var value = data[timestamp];
    var color = getColor(value, theme);
    return [].concat(_toConsumableArray(acc), [{
      key: key,
      value: value,
      color: color
    }]);
  }, []).reduce(function (acc, item) {
    if (['CmNrvStat_1', 'CmScrStat_1'].includes(item.key)) {
      var _acc$plastification;

      var imgUrl = require('@assets/plastification.png');

      var value = item.value,
          color = item.color;

      if (isNil(acc.plastification)) {
        return {
          plastification: {
            value: value,
            color: color,
            imgUrl: imgUrl
          }
        };
      }

      if (!isNil((_acc$plastification = acc.plastification) === null || _acc$plastification === void 0 ? void 0 : _acc$plastification.value) && acc.plastification.value > value) {
        return {
          plastification: Object.assign({}, acc.plastification, {
            value: value,
            color: color
          })
        };
      }

      return acc;
    }

    return acc;
  }, {});
}

export function getColor(value, theme) {
  if (value >= CMStatusSteps.healthy) {
    return theme.palette.secondary.light;
  }

  if (value >= CMStatusSteps.attention) {
    return '#FDD835';
  }

  if (value < CMStatusSteps.attention) {
    return theme.palette.error.main;
  }

  return theme.palette.neutral.main;
}